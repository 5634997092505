import React, { useEffect, useState } from "react";
// import { Firebase,Firestore } from "./Serveurs/Firebase/firebaseConf";
import { Modal,ModalHeader,ModalBody,ModalFooter,Button } from "@windmill/react-ui";

const Notification = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages   , setMessages   ] = useState([]);

  function closeModal() {
    setIsModalOpen(false);
  }

  const performTimeConsumingTask = async () => {

    return new Promise((resolve) =>

      setTimeout(() => { 

        // const messaging = Firebase.messaging();

        //   messaging.requestPermission().then(() => {
        //       return messaging.getToken();
        //     })
        //     .then((data) => {
        //       resolve(data);
        //   });
        //   messaging.onMessage((payload) => {
        //     setIsModalOpen(true);
        //     setMessages(payload.notification);
        //   });
        
      },25000)
    );

  }

  useEffect(() => {

    // performTimeConsumingTask().then((token) => {

    // if (token) {

    //     let T = [];

    //     Firestore.get().then(function (snapshot) {

    //       snapshot.forEach(function (childSnapshot) {
    //         T.push(childSnapshot.data().id);
    //       });

    //       if (T.length >= 0) {

    //         const indice = T.findIndex((tok) => tok === token);

    //         if (indice === -1) {
    //           Firestore.add({ id: token })
    //             .then(function (docRef) {
    //               console.log("Tutorial created with ID: ", docRef.id);
    //             })
    //             .catch(function (error) {
    //               console.error("Error adding Tutorial: ", error);
    //             });
    //         }

    //       }

    //     });

    // }

    // });

  }, []);

  return (
    <>
      {messages ? (

        <Modal isOpen={isModalOpen} onClose={closeModal}>
         
          <ModalHeader>{messages.title}</ModalHeader>

          <ModalBody>
            <div className="modelbody">{messages.body}</div>
          </ModalBody>

          <ModalFooter>
            {/* <Button
              className="w-full sm:w-auto"
              layout="outline"
              onClick={closeModal}
            >
              Cancel
            </Button> */}

            <Button onClick={closeModal} className="w-full sm:w-auto">
              Ok
            </Button>
          </ModalFooter>
       
        </Modal>
        
      ) : (
        <div></div>
      )}
    </>
  );
  
};

export default Notification;
