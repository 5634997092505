/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SiderbarContainer, Icon } from "./SidebarElements";
import "./style.css";

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggle }) => {

  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  }, []);

  const deconnecter = () => {
     if (user != null) {
          signOut(auth).then(() => {
            navigate("/Auth");
          });
     } else {
          navigate("/Auth");
     }
  };

  const inscription = () => {
        navigate("/FInscription");
  };

  return (
  
    <SiderbarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <i className="icofont-close-line"></i>
      </Icon>

      <section className="services">

        <div className="contents">
          <div className="text box ">
            <h2>MENU</h2>
          </div>

          <ul className="acorh">
            <Link to="/">
              <li>
                <a>Acceuil</a>
              </li>
            </Link>

            <li>
              <a>Institution</a>
              <ul>
                <li>
                  <Link to="/Apropos">
                    <a>Présentation de l'Institut</a>
                  </Link>
                </li>
                <li>
                  <Link to="/Directeur">
                    <a>Direction Générale</a>
                  </Link>
                </li>
                <li>
                  <Link to="/Academique">
                    <a>Secrétariat Général Académique</a>
                  </Link>
                </li>
                <li>
                  <Link to="/Administratif">
                    <a>Secrétariat Général Administratif</a>
                  </Link>
                </li>

                <li>
                  <Link to="/Secretaire">
                    <a> Secrétaire Générale à la Recherche </a>
                  </Link>
                </li>

                <li>
                  <Link to="/Administratrice">
                    <a>Administrateur du Budget</a>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a>INSCRIPTION</a>
              <ul>
                <li>
                  <Link to="CInscription">
                    <a>Condition</a>
                  </Link>
                </li>
                <li>
                  <Link to="FInscription">
                    <a>Inscription en ligne</a>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a>Services ALUMNI</a>

              <ul>
                <li>
                  <Link to="/Authentification">
                    <a>Authentification de Piéces Académiques</a>
                  </Link>
                </li>
              </ul>
            </li>

            <Link to="/Actualite">
              <li>
                <a>ACTUALITÉ</a>
              </li>
            </Link>

            <Link to="/Contact" smooth={true}>
              <li>
                <a>CONTACT</a>
              </li>
            </Link>

            <Link to="/Apropos">
              <li>
                <a>APROPOS</a>
              </li>
            </Link>
          </ul>

          <div className="log-sign">

              <button className="button-identification" onClick={()=>deconnecter()}>
                {user?.uid ? "Déconn..." : "S'Identifier"}
              </button>
          
              <button className="button-inscription" onClick={()=>inscription()}>
                S'Inscrire
              </button>
         
          </div>

        </div>
      </section>
    </SiderbarContainer>
  );
  
};

export default Sidebar;
