/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Footer.css';
import logo from '../../assets/img/logoiss.png';

const Footer = () => {

    return (
      <footer className="footer reveal-footer">
        <div className="containers">
          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <div className="widget-item">
                <div className="about-widget">
                  <a
                    className="footer-logo"
                    href="index.html"
                    style={{ paddingTop: 33 }}
                  >
                    <img src={logo} alt="Logo" style={{ height: 50 }}></img>
                  </a>

                  <p className="mb-0">
                    {" "}
                    Institut Supérieur de Statistique de Lubumbashi{" "}
                  </p>
                  <p
                    className="mb-0"
                    style={{ color: "#fff", fontWeight: 900 }}
                  >
                    {" "}
                    Scientia Splendet et Conscientia
                  </p>

                  <ul className="widget-contact-info">
                    <li className="info-address">
                      <i className="icofont-location-pin"></i>
                      1599, Boulevard Lumumba (coin Av. Kambove), Lubumbashi
                    </li>
                    <li className="info-mail">
                      <i className="icofont-email"></i>
                      <a target="_blank" href="https://privateemail.com/">
                        info@iss-lubumbashi.org
                      </a>
                    </li>
                    <li className="info-phone">
                      <i className="icofont-ui-call"></i>
                      <a href="tel://(+243) 998 831 134">+243 840 716 781</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
              <div className="widget-item d-inline-block">
                <h4
                  className="widget-titles line-bottom"
                  style={{
                    color: "#fff",
                  }}
                >
                  Filières Organisées
                </h4>
                <div className="widget-tags">
                  <ul>
                    <li>
                      <a>Fiscalité</a>
                    </li>
                    <li>
                      <a>Marketing</a>
                    </li>
                    <li>
                      <a>Statistique</a>
                    </li>
                    <li>
                      <a>Douane et Accise</a>
                    </li>
                    <li>
                      <a className="active">Informatique de gestion</a>
                    </li>
                    <li>
                      <a>Démographie appliquée</a>
                    </li>
                    <li>
                      <a>Réseau Informatique</a>
                    </li>
                    <li>
                      <a>Sciences commerciales et financières</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <div className="widget-item">
                <h4
                  className="widget-titles line-bottom"
                  style={{ color: "#fff" }}
                >
                  Question
                </h4>
                <nav className="widget-posts">
                  <ul className="posts-item">
                    <li>
                      <a href="blog-details.html">
                        <i className="icon icofont-rounded-double-right"></i>
                        Qu'est-ce que l'ISS Lubumbashi ?
                      </a>
                    </li>

                    <li>
                      <a href="blog-details.html">
                        <i className="icon icofont-rounded-double-right"></i>
                        Pourquoi étudier à l'ISS Lubumbashi ?
                      </a>
                    </li>

                    <li>
                      <a href="blog-details.html">
                        <i className="icon icofont-rounded-double-right"></i>Nos
                        Diplomes sont-ils valables dans le monde ?.{" "}
                      </a>
                    </li>

                    <li>
                      <a href="blog-details.html">
                        <i className="icon icofont-rounded-double-right"></i>Nos
                        partenaires nous font-ils confiance ?.
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3">
              <div className="widget-item">
                <h4
                  className="widget-titles line-bottom"
                  style={{ color: "#fff" }}
                >
                  Feed-back
                </h4>
                <div className="widget-newsletter">
                  <p>
                    {" "}
                    Inscrivez-vous à notre liste de diffusion pour recevoir
                    toutes nos actualités
                  </p>
                  <form className="newsletter-form input-btn-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Entrer votre Email"
                    ></input>
                    <button className="btn btn-theme" type="button">
                      <i className="icofont-long-arrow-right"></i>
                    </button>
                  </form>
                </div>
                <div className="widget-social-icons">
                  <a href="#">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="icofont-google-plus"></i>
                  </a>
                  <a href="#">
                    <i className="icofont-pinterest"></i>
                  </a>
                  <a href="#">
                    <i className="icofont-rss"></i>
                  </a>
                  <a href="#">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="icofont-dribbble"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row text-center">
              <div className="col-sm-12">
                <div className="widget-copyright">
                  <p>
                    <span>© 2021 </span>Institut Supérieur de Statistique de
                    Lubumbashi. |<i class="icofont-responsive icofont-5x"></i>
                    <a target="_blank" href="https://">
                      CODEPRO
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;