import React ,{useState} from 'react'
import Sidebar from './Siderbar/index'
import Head2 from './Navbar/Head2';

const Navigateur2 = () => {

    const [isOpen,setIsOpen] = useState(false);

    // console.log(isOpen)
    
    const toggle = () => { 
          setIsOpen(!isOpen) 
    }

    return (
        <>
            <Sidebar isOpen = {isOpen} toggle = {toggle}/>
            <Head2                     toggle = {toggle}/>
        </>
    )

}

export default Navigateur2;