/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import React, { useState, useEffect } from "react";

import $ from 'jquery';
import AOS from 'aos';
import "./Actualite.css";
import Footer from '../Footer/Footer';
import Navigateur2 from '../../Header/Navigateur2';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { Link } from 'react-router-dom';
import { db } from "../../firebase";
import { excerpt } from ".";

import { useNavigate } from "react-router-dom";

const Actualite = ({user}) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [tags, setTags] = useState([]);
    const [trendBlogs, setTrendBlogs] = useState([]);
    const [messages, setMessages] = useState([]);

    const getTrendingBlogs = async () => {
      const blogRef = collection(db, "blogs");
      const trendQuery = query(
        blogRef,
        // where("trending", "==", "yes"),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(trendQuery);
      let trendBlogs = [];
      querySnapshot.forEach((doc) => {
        trendBlogs.push({ id: doc.id, ...doc.data() });
      });
      setTrendBlogs(trendBlogs);
      setBlogs(trendBlogs);
    };

   useEffect(() => {

      getTrendingBlogs();

      // const unsub = onSnapshot(collection(db, "blogs"),

      //   (snapshot) => {
      //     let list = [];
      //     let tags = [];
      //     snapshot.docs.forEach((doc) => {
      //       tags.push(...doc.get("tags"));
      //       list.push({ id: doc.id, ...doc.data() });
      //     });
      //     const uniqueTags = [...new Set(tags)];
      //     setTags(uniqueTags);
      //     setBlogs(list);
      //     setLoading(false);
      //     // setActive("home");
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );

      // return () => {
      //   unsub();
      //   getTrendingBlogs();
      // };

   }, []);

    useEffect (()=>{

      $(window).on('load', function() {
        AOS.init();
        stylePreloader();
      });
    
      // Preloader
      function stylePreloader() {
        $('body').addClass('preloader-deactive');
      }
    
      // Scroll Top Hide Show
      $(window).on('scroll', function(){
        if ($(this).scrollTop() > 250) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
    
        // Sticky Header
        if($('.sticky-header').length){
          var windowpos = $(this).scrollTop();
          if (windowpos >= 80) {
            $('.sticky-header').addClass('sticky');
          } else {
            $('.sticky-header').removeClass('sticky');
          }
        }
    
      });
    
      //Scroll To Top
      $('.scroll-to-top').on('click', function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
      });
    
      // Reveal Footer JS
      let revealId = $(".reveal-footer"),
         footerHeight = revealId.outerHeight(),
         windowWidth = $(window).width(),
         windowHeight = $(window).outerHeight();
    
      if (windowWidth > 991 && windowHeight > footerHeight) {
        $(".site-wrapper-reveal").css({
          'margin-bottom': footerHeight + 'px'
        });
      }
    
      $(window).on('scroll', function() {
      });
      
      $(window).on('resize', function() {
      });

    
      
    },[]);
      
    return (
      <body>
        <div className="wrapper">
          <Navigateur2/>

          <main
            className="main-content site-wrapper-reveal"
            style={{ paddingTop: 120 }}
          >
            <section className="page-title-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="page-title-content content-style3">
                      <div className="bread-crumbs bread-crumbs-style2">
                        <Link to={"/"}>
                          <a>
                            Acceuil<span className="breadcrumb-sep">/</span>
                          </a>
                        </Link>
                        <span className="active text-black">Blog</span>
                      </div>
                      <h4 className="title">
                        Dernières nouvelles de <span>notre blog</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="blog-area pb-sm-70 pb-lg-90"
              data-padding-bottom="137"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    {blogs.map((item) => {
                      return (
                        <div className="post-items-style2" key={item.id}>
                          <div className="post-item">
                            <div className="thumb">
                              <a>
                                <img
                                  onClick={() =>
                                    navigate(`/blogdetails/${item.id}`)
                                  }
                                  src={item.imgUrl}
                                  alt={item.title}
                                  className="most-popular-img"
                                />
                              </a>
                            </div>
                            <div className="content">
                              <div
                                style={{
                                  flex: 1,
                                }}
                              >
                                <h6
                                  className="categorys catg-color"
                                  onClick={() =>
                                    navigate(`/blogdetails/${item.id}`)
                                  }
                                >
                                  {item.category}
                                </h6>
                              </div>

                              <h5 className="titles">{item.title}</h5>

                              <h4 className="title">

                                <a onClick={() =>
                                    navigate(`/blogdetails/${item.id}`)
                                  }>
                                  {excerpt(item.description, 120)} Lire la suite
                                </a>
                              </h4>

                              <div>
                                {item.timestamp.toDate().toDateString()}
                                <a className="author"></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="pagination-area mb-md-80">
                      <nav>
                        <ul className="page-numbers">
                          <li>
                            <span className="page-number current">1</span>
                          </li>
                          <li>
                            <a className="page-number" href="#">
                              2
                            </a>
                          </li>
                          <li>
                            <a className="page-number" href="#">
                              3
                            </a>
                          </li>
                          <li>
                            <a className="page-number" href="#">
                              4
                            </a>
                          </li>
                          <li>
                            <a className="page-number next" href="#">
                              <i className="icofont-thin-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>

                  </div>

                  <div className="col-lg-4">
                    <div className="sidebar-wrapper blog-sidebar-wrapper">
                      <div className="widget-item">
                        <h4
                          className="widget-title"
                          style={{
                            color: "#000000",
                          }}
                        >
                          CHERCHER
                        </h4>
                        <div className="widget-search-box">
                          <form action="#" method="post">
                            <input
                              type="text"
                              id="search"
                              placeholder="Recherche..."
                            ></input>
                          </form>
                        </div>
                      </div>

                      <div className="widget-item">
                        <h4
                          className="widget-title"
                          style={{
                            color: "#000000",
                          }}
                        >
                          CATÉGORIES
                        </h4>
                        <div className="widget-categories">
                          <ul>
                            <li>
                              <a href="#/">
                                Tout <span>1</span>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                Nouvelles <span>0</span>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                Stage <span>0</span>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                Autres <span>0</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="widget-item">
                        <h4
                          className="widget-title"
                          style={{
                            color: "#000000",
                          }}
                        >
                          MOTS CLÉS
                        </h4>
                        <div className="widget-tags tags-style2">
                          <ul>
                            <li>
                              <a href="#/">DG</a>
                            </li>
                            <li>
                              <a href="#/">SGAC</a>
                            </li>
                            <li>
                              <a href="#/">SGR</a>
                            </li>
                            <li>
                              <a href="#/">Autres</a>
                            </li>
                            <li>
                              <a href="#/"></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>
            
            
          </main>


          <Footer />

          <ScrollToTop />
        </div>
      </body>
    );

};

export default Actualite;