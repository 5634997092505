/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logoiss.png";
import "./Head2.css";

import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

const Head2 = ({ toggle }) => {

   const [user, setUser] = useState(null);

   useEffect(() => {
     auth.onAuthStateChanged((authUser) => {
       if (authUser) {
         setUser(authUser);
       } else {
         setUser(null);
       }
     });
   }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    if (user != null) {
       signOut(auth).then(() => {
         navigate("/Auth");
       });
    } 
  };

   const handleLog = () => {
         navigate("/Auth");
   };
  
  return (
    <>
      <div className="header-area2 header-default transparent sticky-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="header-align">
                <div className="header-logo-area">
                  <a href="/">
                    <img
                      className="logo-main"
                      src={logo}
                      alt="Logo"
                      style={{ height: 50 }}
                    />
                    <img
                      className="logo-light"
                      src={logo}
                      alt="Logo"
                      style={{ height: 50 }}
                    />
                  </a>
                </div>

                <div className="header-navigation-area">

                  <ul className="main-menu nav justify-content-center">
                    <li>
                      <Link to="/">
                        <a>Acceuil</a>
                      </Link>
                    </li>

                    <li className="has-submenu">
                      <a>Institution</a>
                      <ul className="submenu-nav">
                        <li>
                          <Link to="/Apropos">
                            <a>Présentation de l'Institut</a>
                          </Link>
                        </li>

                        <li>
                          <Link to="/Directeur">
                            <a>Direction Générale</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Academique">
                            <a>Secrétariat Général Académique</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Administratif">
                            <a>Secrétariat Général Administratif</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Secretaire">
                            <a>Secrétaire Générale à la Recherche</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Administratrice">
                            <a>Administration du Budget</a>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="has-submenu">
                      <a>INSCRIPTION</a>
                      <ul className="submenu-nav">
                        <li>
                          <Link to="/CInscription">
                            <a>Condition</a>
                          </Link>
                        </li>
                        <li>
                          <Link to="/FInscription">
                            <a>Inscription en ligne</a>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="has-submenu">
                      <a>Services ALUMNI</a>
                      <ul className="submenu-nav">
                        {/* <li><a><Link to="/ALUMNI">Aperçu des services ALUMNI</Link></a></li> */}
                        <li>
                          <a>
                            <Link to="/Authentification">
                              Authentification de Piéces Académiques
                            </Link>
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link to="/Actualite">
                        <a>ACTUALITÉ</a>
                      </Link>
                    </li>

                    <li>
                      <Link to="/Contact">
                        <a>CONTACT</a>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="header-action-area">
                  <div className="login-reg">
                    <a>
                      <a onClick={() => handleLog()}>Connexion</a>
                    </a>
                    <span>/</span>
                    <a>
                      <a onClick={() => handleLogout()}> {user?.uid ? "Déconnexion" : null} </a>
                    </a>

                    <a>
                      <i className="icon icofont-user-alt-3"></i>
                    </a>
                  </div>

                  <div className="widget-language">
                    <span className="current">
                      Fr
                      <i className="icon icofont-simple-down"></i>
                    </span>

                    <ul>
                      <li>
                        <a>Fr</a>
                      </li>
                      <li>
                        <a>En</a>
                      </li>
                    </ul>
                  </div>

                  <button className="btn-menu d-lg-none" onClick={toggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default Head2;
