/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useEffect } from "react";
import Navigateur2 from "../../Header/Navigateur2";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./AddBlog.css";

import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../firebase";
import Messages from "./Messages";

const initialState = {
  title: "",
  tags: [],
  trending: "no",
  category: "",
  description: "",
};

const categoryOption = [
  "Directeur Général",
  "Secrétaire Général Académique",
  "Secrétaire Générale à la Recherche",
  "Secrétaire Général Administratif",
  "Administrateur du Budget",
  "Autres",
];

const AddBlog = ({ user }) => {

  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);

  const [message,setMessage]=useState(false)

  const [progress, setProgress] = useState(null);

  const { id } = useParams();

  const { title, tags, category, trending, description } = form;

  useEffect(() => {

    const uploadFile = () => {

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed",(snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setProgress(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            // toast.info("Image upload to firebase successfully");
            setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile();
  }, [file]);

  useEffect(() => {
    id && getBlogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getBlogDetail = async () => {
    const docRef = doc(db, "blogs", id);
    const snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
    }
    // setActive(null);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setMessage(false);
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
     setMessage(false);
  };

  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
     setMessage(false);
  };

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
     setMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(form);

    if (category && title && description && trending) {
        
      if (!id) {
        try {

          await addDoc(collection(db, "blogs"), {
            ...form,
            timestamp: serverTimestamp(),
            author: user.displayName,
            userId: user.uid,
          });
          console.log("Blog created successfully");
          setMessage(true)

        } catch (err) {
          console.log(err);
           setMessage(false);
        }
      } else {
        try {
          await updateDoc(doc(db, "blogs", id), {
            ...form,
            timestamp: serverTimestamp(),
            author: user.displayName,
            userId: user.uid,
          });
          console.log("Blog updated successfully");
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      return console.log("All fields are mandatory to fill");
    }

  };

  return (
    <body>
      <div className="wrapper">
        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 130 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content content-style3">
                    <div className="bread-crumbs bread-crumbs-style2">
                      <a>
                        <Link to="/">Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active text-black">Add blog</span>
                    </div>

                    <div className="section-title">
                      <h4
                        className="title"
                        data-aos="fade-zoom-in"
                        data-aos-duration="1300"
                      >
                        GESTION <span>DE BLOG</span>
                      </h4>
                      <h5>Annees Academique 2022-2023</h5>
                      {/* <h5>En cours de développement </h5> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="appointments-area" data-bg-color="#fff">
            <div className="appointments-form-style1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="appointments-form">
                      
                      {message ? (
                        <Messages description="Actualité publier avec success" />
                      ) : null}

                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control input-text-box"
                                placeholder="Title"
                                name="title"
                                value={title}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="col-12 py-3">
                                <p className="trending">
                                  Est-ce un blog tendance ?
                                </p>
                                <div className="form-check-inline mx-2">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    value="yes"
                                    name="radioOption"
                                    checked={trending === "yes"}
                                    onChange={handleTrending}
                                  />
                                  <label
                                    htmlFor="radioOption"
                                    className="form-check-label"
                                  >
                                    Yes&nbsp;
                                  </label>
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    value="no"
                                    name="radioOption"
                                    checked={trending === "no"}
                                    onChange={handleTrending}
                                  />
                                  <label
                                    htmlFor="radioOption"
                                    className="form-check-label"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <select
                                value={category}
                                onChange={onCategoryChange}
                                className="catg-dropdown"
                              >
                                <option>
                                  Veuillez selectionner la categorie
                                </option>
                                {categoryOption.map((option, index) => (
                                  <option value={option || ""} key={index}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="col-12 py-3">
                              <textarea
                                // className="form-control description-box"
                                placeholder="Description"
                                value={description}
                                rows="7"
                                name="description"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            {progress}
                            <div className="form-group">
                              <input
                                type="file"
                                className="form-control"
                                onChange={(e) => setFile(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-12 py-3 text-center">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-add"
                          disabled={progress !== null && progress < 100}
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <ScrollToTop />
      </div>
    </body>
  );
};

export default AddBlog;
