import React ,{useState} from 'react'
import Sidebar from './Siderbar/index'
import Head1 from './Navbar/Head1';

const Navigateur1 = () => {

    const [isOpen,setIsOpen] = useState(false);
    
    const toggle = () => { 
          setIsOpen(!isOpen) 
    }

    return (
        <>
            <Sidebar isOpen = {isOpen} toggle = {toggle}/>
            <Head1                     toggle = {toggle}/>
        </>
    )

}

export default Navigateur1;