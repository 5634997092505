/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './Formation.css';

import BGTemoignage from '../../assets/img/photos/BGtemoignage.jpg';

const Formation = () => {

    return (

        <section className="testimonial-area testimonial-default-area bg-img"  id="Temoignage"  style={{ background: `url('${BGTemoignage}') no-repeat center center` }}>
               
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center" data-aos="fade-up" data-aos-duration="1000">
                        <h5>Mission</h5>
                        <h2 className="title">Aperçu <span>général de la formation</span></h2>
                        </div>
                    </div>
                    </div>

                    <div className="row">

                    <div className="col-lg-12">

                        <div className="div-container testimonial-slider-container" data-aos="fade-up" data-aos-duration="1100">
                        
                        <div className="div-wrapper testimonial-slider">

                             <div className="div-slide testimonial-item">

                                    <div className="client-content">
                                        <p>
                                        Nous formons des cadres supérieurs en sciences et techniques appliquées à la gestion dans les filières de Statistique, d'Informatique de gestion, de Sciences commerciales et financières et de Démographie appliquée
                                        </p>
                                    </div>
                                
                                <div className="client-info">

                                <div className="desc">
                                    <h4 className="name">Source</h4>
                                    <p className="client-location">La Direction Générale</p>
                                </div>

                                </div>
                     
                            </div>
                        
                        </div>
                        
                        </div>

                    </div>
                    </div>
                
                </div>

        </section>
   
    );
};

export default Formation;