/* eslint-disable jsx-a11y/img-redundant-alt */

import React from 'react';
import BGFiliere from '../../assets/img/photos/filieres.png'
import colation from '../../assets/formationcolation.png'
import comptabilite from '../../assets/img/icons/comptabilite.png';
import statistiques from '../../assets/img/icons/research.png';
import infoGestion from '../../assets/img/icons/infogestion.png';
import reseaux from '../../assets/img/icons/reseau.png';
import fiscalité from '../../assets/img/icons/fiscalite.png';
import demographie from '../../assets/img/icons/demo128.png';
import marketing from '../../assets/img/icons/Marketing128.png';  
import duoane from '../../assets/img/icons/Douane.png'; 
import './Filiere.css';

const Filiere = () => {

    return (
      <section
        className="feature-area feature-default-area"
        data-bg-color="#fff"
        id="filiere"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <h5>Tu n'as qu'affaire un choix</h5>
                <h2 className="title">
                  Filières <span>Organisées</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-xl-8">
              <div
                className="row icon-box-style1"
                data-aos="fade-up"
                data-aos-duration="1100"
              >
                <div className="col-md-6">
                  <div className="icon-box-item">
                    
                    <div className="icon">
                      <img src={statistiques} alt={statistiques} />
                    </div>

                    <div className="content">
                      <h5 className="title">Statistique</h5>
                      <p>
                        Les cadres formés sont capables de Colleter, présenter,
                        synthétiser et analyser des données statiques,
                        travailler dans un centre de calcul d’une entreprise ou
                        dans le système statistique national.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={infoGestion} alt={infoGestion} />
                    </div>

                    <div className="content">
                      <h5 className="title">
                        Informatique Appliquée à la Gestion des Entreprises
                      </h5>
                      <p>
                        Les cadres formés dans cette filière sont capables de
                        procéder à la gestion d’une entité en recourant au
                        traitement automatique de l’information par
                        l’ordinateur.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={reseaux} alt={reseaux} />
                    </div>
                    <div className="content">
                      <h5 className="title">Réseaux et Télécommunication</h5>
                      <p>
                        Les étudiants formés dans cette discipline, sont
                        capables  de maitriser les principes de la télématique
                        et de la réseautique, en mettant l'accent sur les
                        méthodes, les architectures, les protocoles et les
                        standards de communication.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={demographie} alt={demographie} />
                    </div>
                    <div className="content">
                      <h5 className="title">Démographie appliquée</h5>
                      <p>
                        L’ISS/Lubumbashi forme des cadres capables de travailler
                        dans les organisations tant nationales
                        qu’internationales afin d’intégrer la population dans
                        les programmes de développement économique et social.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={comptabilite} alt={comptabilite} />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Sciences commerciales et financières
                        (comptabilité,Marketing et Banque)
                      </h5>
                      <p>
                        Les étudiants sont formés pour organiser le service de
                        comptabilité d’une entité, d’assurer l’audit interne et
                        externe, de faire une expertise comptable dans une
                        entreprise publique ou privée.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={duoane} alt={duoane} />
                    </div>
                    <div className="content">
                      <h5 className="title">Douane et Accises</h5>
                      <p>
                        Les étudiants formés dans cette discipline, sont
                        capables de faciliter les opérations d’import-export aux
                        particuliers et aux entreprises.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={fiscalité} alt={fiscalité} />
                    </div>
                    <div className="content">
                      <h5 className="title">Fiscalité</h5>
                      <p>
                        Nous formons les cadres capables d'une part d'aider
                        l'Etat congolais à assoir sa politique fiscale pour la
                        maximisation des recettes, et d'autre part d'assister le
                        tiers à la culture fiscale.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="icon-box-item">
                    <div className="icon">
                      <img src={marketing} alt={marketing} />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Conception des systèmes d'Informations
                      </h5>
                      <p>
                        {/* Les étudiants formés sont capables de faire un diagnostic du marché afin
                         de mieux écouler les produits et d’accroitre les ventes. */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="thumb" data-aos="fade-left" data-aos-duration="1500">
          <img src={colation} alt={colation}></img>
        </div>
      </section>
    );
};

export default Filiere;