/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import $ from "jquery";
import AOS from "aos";
import "./Service.css";
import DGIMAGE from "../../assets/img/team/DGISS2.jpeg";
import Footer from "../Footer/Footer";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
import Navigateur2 from "../../Header/Navigateur2";
import FormContact from "./FormContact";

import { CommunicationDG } from "./Data";

import useDownloader from "react-use-downloader";
import Footers from "../Footer/Footers";

const DG = () => {

  const {
    // size,
    elapsed,
    // percentage,
    download,
    // cancel,
    // error,
    // isInProgress,
  } = useDownloader();
    
  useEffect(() => {
    $(window).on("load", function () {
      AOS.init();
      stylePreloader();
    });

    // Preloader
    function stylePreloader() {
      $("body").addClass("preloader-deactive");
    }

    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }

      if ($(".sticky-header").length) {
        var windowpos = $(this).scrollTop();
        if (windowpos >= 80) {
          $(".sticky-header").addClass("sticky");
        } else {
          $(".sticky-header").removeClass("sticky");
        }
      }

      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }
    });

    //Scroll To Top
    $(".scroll-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 800);
      return false;
    });

    // Reveal Footer JS
    let revealId = $(".reveal-footer"),
      footerHeight = revealId.outerHeight(),
      windowWidth = $(window).width(),
      windowHeight = $(window).outerHeight();

    if (windowWidth > 991 && windowHeight > footerHeight) {
      $(".site-wrapper-reveal").css({
        "margin-bottom": footerHeight + "px",
      });
    }

    $(window).on("scroll", function () {});

    $(window).on("resize", function () {});
  }, []);

  return (
    <body>
      <div className="wrapper">
        {/* <div className="preloader-wrap">
          <div className="preloader">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div> */}

        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 140 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content">
                    <div className="bread-crumbs">
                      <a>
                        <Link to="/">Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active">DG</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="department-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="department-wrpp">
                    <div className="sidebar-wrapper">
                      <div className="widget-item">
                        <h4 className="widget-title" style={{ color: "#000" }}>
                          Nos Services
                        </h4>
                      </div>

                      <div className="widget-item">
                        <div className="widget-work-hours">
                          <h4 className="title">Heures de Services</h4>
                          <ul>
                            <li>
                              <span className="work-day">Lundi</span>
                              <span className="work-hours">8:00 - 16:00</span>
                            </li>
                            <li>
                              <span className="work-day">Mardi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Mercredi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Jeudi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Vendredi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Samedi</span>
                              <span className="work-hours">8:00 - 12:00</span>
                            </li>
                          </ul>
                          <a className="btn-theme btn-white" href="#/">
                            EN SAVOIR PLUS
                          </a>
                        </div>
                      </div>

                      <div className="widget-item">
                        <FormContact />
                      </div>

                    </div>

                    <div className="department-content">
                      <h2
                        className="title"
                        data-aos="fade-zoom-in"
                        data-aos-duration="1300"
                      >
                        Directeur Général
                      </h2>

                      <div className="swiper-container department-gallery">
                        <div className="swiper-wrapper gallery-slider">
                          <div className="swiper-slide">
                            <div className="gallery-item">
                              <div className="thumb">
                                <a
                                  className="lightbox-image"
                                  data-fancybox="gallery"
                                  href={DGIMAGE}
                                >
                                  <img src={DGIMAGE} alt="Image"></img>
                                </a>
                                <div className="overlay">
                                  <i className="icofont-plus"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content">
                        <h2 className="title">
                          Profil &<span> Responsabilité</span>
                        </h2>
                        <p>
                          Le chef d’établissement supervise et coordonne
                          l’ensemble des activités de l’institution. Ace titre :
                        </p>

                        <ul className="list-style1">
                          <li>
                            Il assure l’exécution des décisions du conseil
                            d’Administration, du conseil de l’établissement et
                            du comité de gestion 
                          </li>
                          <li>
                            Il préside le conseil de l’établissement et le
                            comité de gestion 
                          </li>
                          <li>
                            Il veille au respect du statut et des règlements de
                            l’établissement 
                          </li>
                          <li>
                            Il exerce tous les pouvoirs du comité de gestion en
                            cas d’urgence 
                          </li>
                          <li>
                            En cas d’urgence, il prend des mesures nécessaires
                            relevant de la compétence du conseil de
                            l’établissement à charge de l’en informer à sa toute
                            prochaine réunion 
                          </li>

                          {/* <li>Il peut convoquer et assister avec voix délibérative aux conseils des facultés/sections, des départements, des institutions et organisation de l’établissement. Dans ce cas, il en assure la présidence. Il peut aussi assister aux jurys d’examens. Dans ce cas, il en assure la présidence. 
                                                    Il a voix délibérative s’il a donné une note prise ne compte lors de la délibération.</li>
                                                 */}
                          <li>
                            Il ouvre et clôture les sessions de cours et
                            d’examens 
                          </li>

                          {/* <li>Il contresigne les diplômes académiques légaux, les diplômes scientifiques et ceux propres à l’établissement. Les recteurs des universités sont habilités à contresigner les diplômes de docteur honoris causa conféré à l’établissement</li>
                                                
                                                <li>Il transmet le projet de prévision budgétaire à la hiérarchie </li>
                                                <li>Il représente l’établissement dans toutes les relations extérieures officielles avec les autorités tant nationales qu’internationales </li>
                                                <li>Il fait un rapport annuel au conseil d’Administration sur le fonctionnement de l’établissement. Dans l’exercice de ses fonctions, il est assisté d’un secrétaire General académique, d’un secrétaire General Administratif et le cas échéant d’un Administrateur de budget.</li> */}
                        </ul>
                      </div>

                      {/* <div className="faq-area">
                        <h2 className="title" style={{ marginBottom: 23 }}>
                          Communications du <span>DG</span>
                        </h2>

                        <div className="accordian-content">
                          <div className="accordion">
                            {CommunicationDG.map((files, index) => {
                              return (
                                <div className="accordion-item">
                                  <div className="accordion-header">
                                    <button
                                      key={index}
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      {files.name}

                                      <a
                                        href={files.file}
                                        download
                                        className="icofont-download"
                                      ></a>

                                      <i 
                                        className="icofont-download"
                                        key={index}
                                        onClick={() =>
                                          download(
                                            files.file,
                                            files.name,
                                          ) 
                                        }
                                      ></i> 

                                      {elapsed ? (
                                        <p
                                          style={{ color: "#e02416" }}
                                          key={index}
                                        >
                                          {elapsed}%
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footers/>
          
        </main>

        <Footer />

        <ScrollToTop />
      </div>
    </body>
  );
};

export default DG;
