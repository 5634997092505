import React, { useEffect, useState } from "react";
import './ScrollToTop.css';

const ScrollToTop =()=> {
  
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
    
  }, []);

  return (
    <div >
      {isVisible && (

        <div className="scroll-to-top" onClick={scrollToTop}>
           <span className="icofont-rounded-up"></span>
        </div>

      )}
    </div>
  );
}

export default ScrollToTop ;