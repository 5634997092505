import React, { useState } from 'react';
import HorizontalTimeline from "react-horizontal-timeline";
import './Calendrier.css';

 const Calendrier =()=> {

    const [curIdx,setCurIdx]= useState(0)

    const DateCalendrier = [

      {
        data: "2021-02-19",
        status: "status",
        statusB: " 0",
        statusE: "In Progress"
      },
      {
        data: "2021-07-09",
        status: "status",
        statusB: "1",
        statusE: "Done"
      },
      {
        data: "2021-011-02",
        status: "status",
        statusB: "2",
        statusE: "In Progress"
      },
      {
        data: "2021-12-14",
        status: "status",
        statusB: "3",
        statusE: "Done"
      }
      
    ];

    const getCalendrier = (value) => {

          switch (value) {
              case 0 :
                  return <tbody>
      
                              <tr>
                                  <td className="day">19 FEVRIER</td>
                                  <td>Ouverture solennelle de l’année académique 2020-2021 </td>
                              </tr>
                              <tr>
                                  <td className="day">22 FEVRIER </td>
                                  <td>Début  des cours du premier semestre</td>
                              </tr>
                              <tr>
                                  <td className="day">23 - 29 Avril</td>
                                  <td>
                                    Déroulement des enseignements du premier semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">03 MAI </td>
                                  <td>Début de la période d’enrôlement aux examens du premier semestre jusqu’au mardi 15 Juin</td>
                              </tr>
      
                              <tr>
                                  <td className="day">26 MAI </td>
                                  <td>
                                    Fin des cours du premier semestre
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">28 MAI </td>
                                  <td>
                                  Début de la période de préparation aux examens du premier semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">08 JUIN </td>
                                  <td>
                                  Fin de la période de préparation aux examens du premier semestre
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">09 JUIN </td>
                                  <td>
                                  Début des examens du premier semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">19 JUIN </td>
                                  <td>
                                  Fin des examens du premier semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">02 JUILLET  </td>
                                  <td>
                                  Communication des résultats des examens du premier aux étudiants
                                  </td>
                              </tr>

                          </tbody>
              case 1:
                  return <tbody>
                              <tr>
                                  <td className="day">09 JUILLET  </td>
                                  <td>
                                  Début des cours du second semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">AOUT 2021</td>
                                  <td>
                                    Suite normale des activités des enseignements du second semestre
                                  </td>
                              </tr>
                                            
                              <tr>
                                  <td className="day">08 SEPTEMBRE </td>
                                  <td>
                                  Début de la période d’enrôlement  aux examens du second semestre jusqu’au 27 Octobre.
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">29 SEPTEMBRE</td>
                                  <td>
                                    Fin des cours du second semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">01 OCTOBRE </td>
                                  <td>
                                    Début de la période de préparation des examens du second semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">11 OCTOBRE </td>
                                  <td>
                                      Fin de la période de préparation aux examens du second semestre ; Date limite du dépôt des travaux : TFC/TFE/mémoire
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">12 OCTOBRE </td>
                                  <td>
                                      Début des examens du second semestre
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">12 OCTOBRE </td>
                                  <td>
                                    Fin des examens du second semestre
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">29 OCTOBRE </td>
                                  <td>
                                      Début de la période de délibération et proclamation des résultats
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">30 OCTOBRE </td>
                                  <td>
                                    samedi 30 Collation des grades académiques et clôture de l’année académique 2020-2021
                                  </td>
                              </tr>
      
                          </tbody>
              case 2:
                  return <tbody>
                                          
                              <tr>
                                  <td className="day">02 NOVEMBRE  </td>
                                  <td>
                                    Début de la période d’enrôlement  aux examens de la seconde session
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">15 NOVEMBRE</td>
                                  <td>
                                    Début des examens de la seconde session
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">25 NOVEMBRE </td>
                                  <td>
                                    Fin des examens de la seconde session
                                  </td>
                              </tr>
      
                              <tr>
                                  <td className="day">04 DECEMBRE </td>
                                  <td>
                                    Début de la période de la délibération et de la proclamation des résultats de la seconde session
                                  </td>
                              </tr>
                              <tr>
                                  <td className="day">10 DECEMBRE </td>
                                  <td>
                                      Fin de la période de la publication des résultats de la seconde session
                                  </td>
                              </tr>
      
                        </tbody>
              case 3: 
                  return <div className="calendrier" >
                              <h1>Ouverture solennelle de l’année académique 2021-2022</h1>
                          </div>
              default:
                  return null
          }
    }
  
    return (

        <section >

            <div className="container">

                <div className="row">
                    <div className="col-lg-12">
                    
                        <div className="section-title text-center" data-aos="fade-up" data-aos-duration="1000">
                            <h5>100 % CONGOLAIS</h5>
                            <h2 className="title">Calendrier <span>Academique</span></h2>
                        </div>

                    </div>
                </div>

                <div className="contenaireHorizontal" >
            
                        <HorizontalTimeline 
                            styles = {{ 
                                background: "#fff",
                                foreground: "#f9220e",
                                outline: "#dfdfdf",
                            }}
                            
                            index = {curIdx}

                            indexClick = {index => { 
                                setCurIdx(index); 
                                console.log(index)
                            }}     
                            
                            values = { DateCalendrier.map(x => x.data)}
                        />

                 </div>

                 <div className="schedule">

                    <div className="container">

                        <div className="content">
                            
                            <div className="box timing wow slideInRight">

                                <table className="table">

                                    {getCalendrier(curIdx)}
                            
                                </table>
                            
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>
            
            </div>
        
        </section> 

    
    );

}

export default Calendrier ; 