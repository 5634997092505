/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import React, { useState, useEffect } from "react";

import $ from "jquery";
import AOS from "aos";
import "./Actualite.css";
import Footer from "../Footer/Footer";
import Navigateur2 from "../../Header/Navigateur2";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useParams, Link } from "react-router-dom";
import { db } from "../../firebase";
import { excerpt } from ".";

import main_slide from "../../assets/img/photos/BGtemoignage.jpg";


const Blogdetails = () => {

  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  const [blogs, setBlogs] = useState([]);
  const [tags, setTags] = useState([]);

   useEffect(() => {
     id && getBlogDetail();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id]);

   const getBlogDetail = async () => {
     const docRef = doc(db, "blogs", id);
     const blogDetail = await getDoc(docRef);
     setBlog(blogDetail.data());
    //  setActive(null);
   };

  return (
    <body>
      <div className="wrapper">
        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 120 }}
        >
          <section class="page-title-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="page-title-content content-style4">
                    <div class="bread-crumbs bread-crumbs-style2">
                      <a href="/Actualite">
                        Actualité<span class="breadcrumb-sep">/</span>
                      </a>
                      <span class="active text-black">Détails</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="blog-details-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <div class="post-details-content">
                    <div class="post-details-body">
                      <div class="content">
                        <h2 class="title">{blog?.title}</h2>

                        <img src={blog?.imgUrl} alt={blog?.category}></img>

                        <div class="meta">
                          <a class="category">{blog?.category}</a>
                          <span>{blog?.timestamp.toDate().toDateString()}</span>
                        </div>

                        <p>{blog?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="sidebar-wrapper blog-sidebar-wrapper mb-md-80 pt-md-10 pt-160">
                    <div class="widget-item">
                      <h4 class="widget-title">Recherche</h4>
                      <div class="widget-search-box">
                        <form action="#" method="post">
                          <input
                            type="text"
                            id="search"
                            placeholder="Type d'actualité"
                          ></input>
                        </form>
                      </div>
                    </div>

                    <div class="widget-item">
                      <h4
                        class="widget-title"
                        style={{
                          color: "#000000",
                        }}
                      >
                        CATÉGORIES
                      </h4>
                      <div class="widget-categories">
                        <ul>
                          <li>
                            <a href="#/">
                              Tout <span>1</span>
                            </a>
                          </li>
                          <li>
                            <a href="#/">
                              Nouvelles <span>0</span>
                            </a>
                          </li>
                          <li>
                            <a href="#/">
                              Stage <span>0</span>
                            </a>
                          </li>
                          <li>
                            <a href="#/">
                              Autres <span>0</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="widget-item">
                      <h4
                        class="widget-title"
                        style={{
                          color: "#000000",
                        }}
                      >
                        MOTS CLÉS
                      </h4>
                      <div class="widget-tags tags-style2">
                        <ul>
                          <li>
                            <a href="#/">DG</a>
                          </li>
                          <li>
                            <a href="#/">SGAC</a>
                          </li>
                          <li>
                            <a href="#/">SGR</a>
                          </li>
                          <li>
                            <a href="#/">Autres</a>
                          </li>
                          <li>
                            <a href="#/"></a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />

        <ScrollToTop />
      </div>
    </body>
  );
};

export default Blogdetails;
