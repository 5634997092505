import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import main_slide from "../assets/img/slider/choix.jpg";

import $ from "jquery";

import "../assets/css/style.css";

import Rendezvous from "../Companents/Rendez-vous/Rendezvous";
import Filiere from "../Companents/Filiere/Filiere";
import Comite from "../Companents/Comite/Comite";
import Temoignage from "../Companents/Temoignage/Temoignage";
import Formation from "../Companents/Formation/Formation";
import Calendrier from "../Companents/Calendrier/Calendrier";

import Maps from "../Companents/Map/Maps";
import Footer from "../Companents/Footer/Footer";
import Acceuil from "./Acceuil/Acceuil";
import Whatsappwidget from "../Companents/WhatApp/Whatsappwidget";
import Navigateur1 from "../Header/Navigateur1";
import Notification from "../Notification";

// https://www.npmjs.com/package/react-modal

import "swiper/swiper.min.css";

const Main = () => {

  useEffect(() => {
    
    $(window).on("load", function () {
      AOS.init();
      $("body").addClass("preloader-deactive");
    });

    $(window).on("scroll", function () {

      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }

      if ($(this).scrollTop() > 250) {
        $(".Whatsappwidget").fadeIn();
      } else {
        $(".Whatsappwidget").fadeOut();
      }

      if ($(".sticky-header").length) {
        var windowpos = $(this).scrollTop();
        if (windowpos >= 80) {
          $(".sticky-header").addClass("sticky");
        } else {
          $(".sticky-header").removeClass("sticky");
        }
      }

      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }
    });

    //Scroll To Top
    $(".scroll-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 800);
      return false;
    });

    // Reveal Footer JS
    let revealId = $(".reveal-footer"),
    
      footerHeight = revealId.outerHeight(),
      windowWidth = $(window).width(),
      windowHeight = $(window).outerHeight();

      console.log(windowWidth)

      $(".site-wrapper-reveal").css({
        "margin-bottom": footerHeight + "px",
      });
      
    if (windowWidth > 991 && windowHeight > footerHeight) {
      
    }

    $(window).on("scroll", function () {});
    $(window).on("resize", function () {});
   
  }, []);

  return (
    <body>
      <div className="wrapper home-default-wrapper">

        <Navigateur1 />

        <Notification />

        <main className="main-content site-wrapper-reveal">

          <Acceuil
            Titre1="Institut Supérieur de Statistique de Lubumbashi"
            Titre2="Envie d'etudier ?"
            Titre3="Vous êtes au bon endroit."
            image={main_slide}
          />

          <Rendezvous />
          <Filiere />
          <Formation />
          <Comite />
          <Temoignage />

          <br />
          <br />
          <br />
          {/* <Frais /> */}
          <Calendrier />
          <br />
          <br />
          <Maps />
        </main>

        <Footer />

        <div className="Whatsappwidget">
          <Whatsappwidget />
        </div>

        <div className="scroll-to-top">
          <span className="icofont-rounded-up"></span>
        </div>
      </div>
    </body>
  );
};

export default Main;  
