/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect,useState } from "react";
import $ from "jquery";
import AOS from "aos";
import "./AuthentificationPieces.css";
import Footer from "../Footer/Footer";
import Footers from "../Footer/Footers";
import Navigateur2 from "../../Header/Navigateur2";
import { Link } from "react-router-dom";
import PaymentCard from 'react-payment-card-component'

const AuthentificationPieces = () => {

    const [isOpen,setIsOpen] = useState(false);

    const toggle = () => { 
            setIsOpen(!isOpen);
            console.log(isOpen)
    }

  useEffect(() => {
    $(window).on("load", function () {
      AOS.init();
      stylePreloader();
    });

    // Preloader
    function stylePreloader() {
      $("body").addClass("preloader-deactive");
    }

    // Scroll Top Hide Show
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }

      // Sticky Header
      if ($(".sticky-header").length) {
        var windowpos = $(this).scrollTop();
        if (windowpos >= 80) {
          $(".sticky-header").addClass("sticky");
        } else {
          $(".sticky-header").removeClass("sticky");
        }
      }
    });

    //Scroll To Top
    $(".scroll-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 800);
      return false;
    });

    // Reveal Footer JS
    let revealId = $(".reveal-footer"),
      footerHeight = revealId.outerHeight(),
      windowWidth = $(window).width(),
      windowHeight = $(window).outerHeight();

    if (windowWidth > 991 && windowHeight > footerHeight) {
      $(".site-wrapper-reveal").css({
        "margin-bottom": footerHeight + "px",
      });
    }

    $(window).on("scroll", function () {});

    $(window).on("resize", function () {});
  }, []);

  return (
    <body>
      <div className="wrapper">
        {/* <div className="preloader-wrap">
          <div className="preloader">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div> */}

        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 140 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content content-style3">
                    <div className="bread-crumbs bread-crumbs-style2">
                      <a>
                        <Link to="/">Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active text-black">Présentation</span>
                    </div>
                    <h4
                      className="title"
                      data-aos="fade-zoom-in"
                      data-aos-duration="1300"
                    >
                      Authentification de <span>pièces académiques</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="blog-area pb-sm-70 pb-lg-90"
            data-padding-bottom="137"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-20">
                  <div className="apropos">
                    <div className="post-item">
                      <div className="content">
                        <h4 style={{ paddingTop: 20 }}>
                          Description du service
                        </h4>

                        <h4 className="title">
                          <a>
                            L'authentification de pièces académiques consiste,
                            pour une personne physique, un établissement
                            d'enseignement supérieur, une institution
                            spécialisée dans la validation de pièces
                            académiques, une organisation quelconque, de
                            demander à nos services académiques d'attester si
                            une pièce académique{" "}
                            <a style={{ fontWeight: 600 }}>
                              (relevé, attestation, diplôme, etc.)
                            </a>{" "}
                            prétendue délivrée par l'ISS/Lubumbashi est
                            authentique ou si une personne prétendue avoir
                            étudié à l'ISS/Lubumbashi a réellement été
                            régulièrement inscrite.
                          </a>

                          <ul></ul>
                        </h4>
                      </div>
                    </div>

                    <div className="post-item">
                      <div className="content">
                        <h4 style={{ paddingTop: 5 }}>Procédure à suivre</h4>

                        <h4 className="title">
                          <a>La procédure se déroule en cinq étapes :</a>

                          <ul></ul>

                          <ul style={{ marginLeft: "0px" }}>
                            <p>
                              {" "}
                              <a style={{ fontWeight: 600 }}> 1.</a>Paiement de
                              frais d'authentification par l'étudiant qui désire
                              faire authentifier ses pièces académiques dans le
                              compte en banque indiquée ci-dessous, les frais de
                              transaction bancaire étant à la charge de
                              l'étudiant ;
                            </p>
                            <p>
                              {" "}
                              <a style={{ fontWeight: 600 }}> 2.</a>Versement de
                              la pièce justificative par l'étudiant à
                              l'institution qui sollicite l'authentification en
                              prenant soin de mentionner explicitement le numéro
                              de référence de la pièce ;
                            </p>
                            <p>
                              {" "}
                              <a style={{ fontWeight: 600 }}> 3.</a>Demande
                              d'authentification de pièces par l'institution
                              requérante en envoyant un émail à l'adresse{" "}
                              <a style={{ fontWeight: 600 }}>
                                communication@iss-lubumbashi.org
                              </a>
                               avec comme objet{" "}
                              <a style={{ fontWeight: 600 }}>
                                "Authentification NOMS CANDIDAT"
                              </a>{" "}
                              et, en pièces jointes, les pièces académiques à
                              authentifier ainsi que la copie scannée de la
                              preuve de paiement au format{" "}
                              <a style={{ fontWeight: 600 }}>
                                .doc, .docx, .pdf, .jpg, .jpeg, .png, .bmp, .gif
                              </a>{" "}
                              ;
                            </p>
                            <p>
                              {" "}
                              <a style={{ fontWeight: 600 }}> 4.</a>
                              Authentification des pièces jointes par les
                              services académiques de l'ISS/Lubumbashi ;
                            </p>
                            <p>
                              {" "}
                              <a style={{ fontWeight: 600 }}> 5.</a>Réponse de
                              l'ISS/Lubumbashi à l'institution requérante.
                            </p>
                          </ul>
                        </h4>
                      </div>
                    </div>

                    <div className="post-item">
                      <div className="content">
                        <h4 style={{ paddingTop: 20 }}>
                          Les frais d’authentification
                        </h4>

                        <h4 className="title">
                          <a>
                            Les frais exigés à un requérant sont fixés à
                            cinquante <a style={{ fontWeight: 600 }}>(50)</a>
                            dollars américains.
                          </a>

                          <p>
                            {" "}
                            <a>
                              {" "}
                              Les coordonnées bancaires dont question sont :
                            </a>
                          </p>

                          <p>
                            <a>Nom de la banque : </a>
                            <a style={{ fontWeight: 600 }}>RAWBANK</a>
                          </p>

                          <p>
                            <a>Code SWIFT :</a>
                            <a style={{ fontWeight: 600 }}>RAWBCDKIXXX</a>
                          </p>

                          <p>
                            <a>Intitulé du compte :</a>
                            <a style={{ fontWeight: 600 }}>
                              INST SUP STAT/FONDS PDS
                            </a>
                          </p>

                          <p>
                            <a>N° coordonnées du compte :</a>
                            <a style={{ fontWeight: 600 }}>
                              05100-45130-01025218008-30
                            </a>
                          </p>

                          <a></a>
                        </h4>
                      </div>
                    </div>

                    {/* <PaymentCard
                       bank="default"
                       model="prime"
                       type="gold"
                       brand="visa"
                       number="45130-01025218008-30"
                       cvv="202"
                       holderName="INST SUP STAT/FONDS PDS"
                       expiration="12/20"
                       flipped={isOpen}
                    />

                    <br /> */}

                    {/* <h1 onClick={toggle}></h1> */}

                  </div>
                </div>
              </div>
            </div>

            <Footers />
          </section>
        </main>

        <Footer />
      </div>
    </body>
  );
};

export default AuthentificationPieces;