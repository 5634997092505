import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCBXdJm97vPw61Wfo6wWylo3GbMHprw6q8",
  authDomain: "iss-gestion-academique.firebaseapp.com",
  databaseURL: "https://iss-gestion-academique.firebaseio.com",
  projectId: "iss-gestion-academique",
  storageBucket: "iss-gestion-academique.appspot.com",
  messagingSenderId: "843337850082",
  appId: "1:843337850082:web:45d123c578471e8bfa92bb",
};

const app     = initializeApp(firebaseConfig);
const auth    = getAuth(app);
const db      = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
