/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Footers from "../Footer/Footers";
import Navigateur2 from "../../Header/Navigateur2";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
//
import * as emailjs from "emailjs-com";
import "./Forms.css";

const SERVICE_ID = "iss_service_id";
const TEMPLATE_ID = "iss_template_id";
const USER_ID = "user_4VqSunVFNxwGW7fIaGjJw";

const FormInscription = () => {

  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [messages, setMessage] = useState("");
  const [test, setTest] = useState(false);

  // Identite
  const [nom          ,                   setNom] = useState("");
  const [postnom      ,               setPostNom] = useState("");
  const [lieunaissance,         setLieunaissance] = useState("");
  const [datenaissance,         setDateNaissance] = useState("");
  const [sexe         ,                  setSexe] = useState("");
  const [nationalite  ,           setNationalite] = useState("");
  const [nompere      ,               setNomPere] = useState("");
  const [nommere      ,               setNomMere] = useState("");
  const [province     ,              setProvince] = useState("");
  const [district     ,              setDistrict] = useState("");
  const [territoire   ,            setTerritoire] = useState("");
  const [adresse      ,               setAdresse] = useState("");
  const [contacturgent,         setContactUrgent] = useState("");

  // Etude
  const [nomecole     ,                 setNomEcole] = useState("");
  const [provinceecole,         setProvinceEcole] = useState("");
  const [territoioreecole ,   setTerritoireecole] = useState("");
  const [section      ,               setSection] = useState("");
  const [option       ,                setOption] = useState("");
  const [nomcentre    ,                setCentre] = useState("");
  const [anneediplpme ,          setAnneeDiplome] = useState("");
  const [pourcentega  ,           setPourcentage] = useState("");
  const [numerodiplome,         setNumeroDiplome] = useState("");
  // 
  const [activitepro  ,           setActivitePro] = useState("");
  const [etudepost    ,             setEtudePost] = useState("");
  const [choixSection ,          setChoixSection] = useState("");

  const [choix1      ,                 setChoix1] = useState("");
  const [choix2      ,                 setChoix2] = useState("");
  const [choix3      ,                 setChoix3] = useState("");

  const handleClick = (e) => {
    e.preventDefault();

    var data = {
      To_email: email,
      Nom: nom,
      Message: messages,
      Email: email,
      Telephone: telephone,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then(
      (resultat) => {
        setTimeout(() => {
          setTest(true);
        }, 3000);
      },
      (error) => {}
    );

    e.target.reset();
  };

  function step2Validator() {
     if (email) {
         return true
     };
  }

  const Idendite = (
    <section className="appointments-area" data-bg-color="#fff">
      <div className="appointments-form-style1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appointments-form">
                <div
                  className="section-title"
                  style={{
                    textalign: "left",
                  }}
                >
                  <h2 className="title">
                    Idendité <span> du candidat </span>
                  </h2>
                </div>

                <form onSubmit={handleClick}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="con_name"
                          placeholder="Nom"
                          onChange={(event) => setNom(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Postnom/Prénom"
                          placeholder="Postnom/Prénom"
                          onChange={(event) => setPostNom(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Lieu de naissance"
                          required
                          placeholder="Lieu de naissance"
                          onChange={(event) =>
                            setLieunaissance(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Date de naissance"
                          placeholder="Date de naissance"
                          onChange={(event) => setDateNaissance(event.target.value)}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Sexe"
                          placeholder="Sexe"
                          onChange={(event) => setSexe(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Nationalité"
                          placeholder="Nationalité"
                          onChange={(event) => setNationalite(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Nom du père"
                          required
                          placeholder="Nom du père"
                          onChange={(event) => setNomPere(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Nom de la Mère"
                          placeholder="Nom de la Mère"
                          onChange={(event) => setNomMere(event.target.value)}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Province d'origine des parents"
                          placeholder="Province d'origine des parents"
                          onChange={(event) => setProvince(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="District"
                          placeholder="District"
                          onChange={(event) => setDistrict(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Territoire"
                          required
                          placeholder="Territoire"
                          onChange={(event) => setTerritoire(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <textarea
                          name="Adresse du candidat à Lubumbashi"
                          rows="7"
                          placeholder="Adresse du candidat à Lubumbashi"
                          onChange={(event) => setAdresse(event.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="form-message"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Etude = (
    <section className="appointments-area" data-bg-color="#fff">
      <div className="appointments-form-style1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appointments-form">
                <div className="section-title">
                  <h2 className="title">
                    Etudes Secondaires <span>Faites</span>
                  </h2>
                </div>

                <form onSubmit={handleClick}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Nom"
                          placeholder="Nom de l'ecole secondaire fréquentée"
                          onChange={(event) => setNomEcole(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Pro"
                          placeholder="Province"
                          onChange={(event) =>
                            setProvinceEcole(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="ter"
                          required
                          placeholder="Territoire/Commune"
                          onChange={(event) =>
                            setTerritoireecole(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Section"
                          placeholder="Section"
                          onChange={(event) => setSection(event.target.value)}
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Option"
                          placeholder="Option"
                          onChange={(event) => setOption(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Nomcentre"
                          placeholder="Nom du centre de l'examen d'Etat"
                          onChange={(event) => setCentre(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="numerique"
                          name="Annee"
                          required
                          placeholder="Année de l'Obtention du Diplome d'Etat"
                          onChange={(event) =>
                            setAnneeDiplome(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="numerique"
                          name="Pourcentage"
                          placeholder="pourcentage"
                          onChange={(event) =>
                            setPourcentage(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Numéro"
                          placeholder="Numéro du diplome"
                          onChange={(event) => setNumeroDiplome(event.target.value)}
                        ></input>
                      </div>
                    </div>

                  </div>

                </form>
              </div>

              <div className="form-message"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Occupations = (
    <section className="appointments-area" data-bg-color="#fff">
      <div className="appointments-form-style1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appointments-form">
                <div className="section-title">
                  <h2 className="title">
                    Occupations <span> apres les Humanites </span>
                  </h2>
                </div>

                <form onSubmit={handleClick}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="Activités professionnelles"
                          placeholder="Activités professionnelles"
                          onChange={(event) =>
                            setActivitePro(event.target.value)
                          }
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <textarea
                          name="Etudes postsecondaires entreprises"
                          rows="7"
                          placeholder="Etudes postsecondaires entreprises"
                          onChange={(event) => setEtudePost(event.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="form-message"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const choix = (
    <section className="appointments-area" data-bg-color="#fff">
      <div className="appointments-form-style1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appointments-form">
                <div className="section-title">
                  <h2 className="title">
                    Choix <span> Formule </span>
                  </h2>
                </div>

                <form onSubmit={handleClick}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          required
                          name="con_name"
                          placeholder="Nom"
                          onChange={(event) => setNom(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="choix1"
                          placeholder="Premier choix"
                          onChange={(event) => setChoix1(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="choix2"
                          placeholder="Deuxième choix"
                          onChange={(event) => setChoix2(event.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="choix3"
                          placeholder="Troisième choix"
                          onChange={(event) => setChoix3(event.target.value)}
                        ></input>
                      </div>
                    </div>

                  </div>
                </form>
              </div>

              <div className="form-message"></div>
            </div>
          </div>
        </div>
      </div>
  
    </section>

  );

  return (
    <body>
      <div className="wrapper">
        
        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 130 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content content-style3">
                    <div className="bread-crumbs bread-crumbs-style2">
                      <a>
                        <Link to="/">Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active text-black">Inscription</span>
                    </div>

                    <div className="section-title">
                      <h4
                        className="title"
                        data-aos="fade-zoom-in"
                        data-aos-duration="1300"
                      >
                        FORMULAIRE DE DEMANDE <span>D'INSCRIPTION</span>
                      </h4>
                      {/* <h5>Annees Academique 2022-2023</h5> */}
                      <h5>En cours de développement </h5>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="blog-area pb-sm-70 pb-lg-90"
            data-padding-bottom="137"
          >
            <StepProgressBar
              startingStep={0}
              previousBtnName="Retour"
              steps={[
                {
                  label: "Idendite",
                  name: "",
                  content: Idendite,
                },
                {
                  label: "Etude",
                  name: "",
                  content: Etude,
                },
                {
                  label: "Occupations",
                  name: "",
                  content: Occupations,
                  validator: step2Validator,
                },
                {
                  label: "choix",
                  name: "",
                  content: choix,
                  validator: step2Validator,
                },
              ]}
            />

            <Footers />
          </section>
        </main>

        {/* <ScrollToTop /> */}
      </div>
    </body>
  );

};

export default FormInscription;
