import styled from 'styled-components';

export const SiderbarContainer = styled.aside`
 
    position:fixed;
    z-index:999;
    width:100%;
    height:100%;
    background:var(--white);
    display:grid;
    align-items:center;
    top :0;
    left:0;
    opacity : ${({isOpen})=>(isOpen ? '100%': '0'  )};
    top     : ${({isOpen})=>(isOpen ? '0%': '100%')}; 
    transition: all 0.5s ease-out; 
     
`

export const Icon = styled.div`
    position:absolute;
    top: 14px;
    right:1.5rem;
    background:transparent;
    font-size:2rem;
    cursor:pointer;
    outline:none;
`