import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import Main from "./Main";
import Apropos from "./Apropos/Apropos";

import Contacts from "./Contact/Contacts";
import DG from "./Services/DG";
import ACADEMIQUE from "./Services/ACADEMIQUE";
import ADM from "./Services/ADM";
import SGR from "./Services/SGR";
import AB from "./Services/AB";

import Inscription from "./Inscription/Inscription";
import AuthentificationPieces from "./AuthentificationPieces/AuthentificationPieces";
import Actualite from "./Actualite/Actualite";
import FormInscription from "./FormInscription/FormInscription";
import { auth } from "../firebase";
import AddBlog from "./Actualite/AddBlog";
import Auth from "./Actualite/Auth";
import Blogdetails from "./Actualite/Blogdetails";

const RoutesForm = () => {

     const [user    , setUser] = useState(null);

      useEffect(() => {
        auth.onAuthStateChanged((authUser) => {
          if (authUser) {
            setUser(authUser);
          } else {
            setUser(null);
          }
        });
      }, []);

      // console.log(user)
  
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />

      <Route path="/Auth" element={<Auth user={user} />} />
      <Route path="/AddBlog" element={<AddBlog user={user} />} />

      <Route path="/blogdetails/:id" element={<Blogdetails/>} />

      <Route path="/Apropos" element={<Apropos />} />
      <Route path="/Actualite" element={<Actualite user={user} />} />
      <Route path="/Contact" element={<Contacts />} />
      <Route path="/CInscription" element={<Inscription />} />
      <Route path="/FInscription" element={<FormInscription />} />
      <Route path="/Authentification" element={<AuthentificationPieces />} />
      <Route path="/Directeur" element={<DG />} />
      <Route path="/Academique" element={<ACADEMIQUE />} />
      <Route path="/Administratif" element={<ADM />} />
      <Route path="/Secretaire" element={<SGR />} />
      <Route path="/Administratrice" element={<AB />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
};

export default RoutesForm;
