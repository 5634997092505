/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React ,{useEffect} from 'react';
import $ from 'jquery';
import AOS from 'aos';
import './Inscription.css';
import Navigateur2 from '../../Header/Navigateur2';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Footers from '../Footer/Footers';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const Inscription = () => {

    useEffect (()=>{

      $(window).on('load', function() {
        AOS.init();
        stylePreloader();
      });
    
      // Preloader
      function stylePreloader() {
        $('body').addClass('preloader-deactive');
      }
    
      // Scroll Top Hide Show
      $(window).on('scroll', function(){
        if ($(this).scrollTop() > 250) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
    
        // Sticky Header
        if($('.sticky-header').length){
          var windowpos = $(this).scrollTop();
          if (windowpos >= 80) {
            $('.sticky-header').addClass('sticky');
          } else {
            $('.sticky-header').removeClass('sticky');
          }
        }
    
      });
    
      //Scroll To Top
      $('.scroll-to-top').on('click', function(){
        $('html, body').animate({scrollTop : 0},800);
        return false;
      });
    
      // Reveal Footer JS
      let revealId = $(".reveal-footer"),
        footerHeight = revealId.outerHeight(),
        windowWidth = $(window).width(),
        windowHeight = $(window).outerHeight();
    
      if (windowWidth > 991 && windowHeight > footerHeight) {
        $(".site-wrapper-reveal").css({
          'margin-bottom': footerHeight + 'px'
        });
      }
    
      $(window).on('scroll', function() {
      });
      
      $(window).on('resize', function() {
      });
      

    },[]);
  
    return (

        <body>

            <div className="wrapper">
      
            
              <Navigateur2/>

              <main className="main-content site-wrapper-reveal" style={{paddingTop:140}}>
              
                <section className="page-title-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="page-title-content content-style3">
                          <div className="bread-crumbs bread-crumbs-style2"><a><Link to="/">Acceuil</Link><span className="breadcrumb-sep">/</span></a><span className="active text-black">Inscription</span></div>
                          <h4 className="title"  data-aos="fade-zoom-in" data-aos-duration="1300">Conditions <span>et procédure d'inscription</span></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="blog-area pb-sm-70 pb-lg-90" data-padding-bottom="137">
                 
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-20">

                        <div className="inscription">
                        
                          <div className="post-item">
                           
                            <div className="content">

                              <h4 className="title">

                                <a>
                                  La procédure d'inscription en 1er graduat à l'ISS répond aux étapes suivantes :
                                </a>

                                <ul></ul>
                                
                                <ul style={{marginLeft:'0px'}}> 
                                  <p>
                                   1. Les inscriptions se font chaque jour de 8h00 à 15h00 aux bâtiments administratifs de l'ISS situés au  croisement des avenues LUMUMBA et KAMBOVE. Là, le candidat doit retirer le bulletin de demande d'inscription et une farde à l'Administration du budget et la fiche de scolarité au service des inscriptions.
                                  </p>
                                  <p>
                                   2. Le candidat part verser les frais d'inscription à la RAW BANK BEL AIR sur Chaussée de KASENGA, arrêt FINA. On lui remet un bordereau de versement. Notons que les frais à payer à la banque sont de l'ordre de trente dollars (30$) pour les candidats ayant obtenu moins de 60% aux examens d'Etat et de vingt dollars (20$) pour ceux ayant obtenu 60% ou plus aux examens d'Etat.
                                  </p>
                                  <p>
                                   3. Le candidat constitue un dossier à verser dans la farde reçue comprenant les éléments suivants : (1) le bulletin d'inscription et la fiche de scolarité dûment remplis, (2) une photocopie du diplôme d'Etat ou une copie du journal s'il n'est pas en possession de son diplôme d'Etat, (3) un certificat d'aptitude physique, (4) une attestation de naissance, (5) une attestation de bonne conduite, vie et mœurs, (6) une photocopie des bulletins de 5e et 6e secondaires, (7) deux photos passeport, (8) un reçu remis par l'Administration du budget sur présentation du bordereau de versement des frais d'inscription délivré par la banque et une copie de ce bordereau. Ce dossier doit être déposé à temps au service des inscriptions.
                                  </p>
                                  <p>
                                   4. Les candidats ayant obtenu moins de 60% à l'examen d'Etat doivent présenter l'examen d'admission à une date qui sera fixée par le Secrétaire Général Académique.
                                  </p>
                                </ul>

                              </h4>

                            </div>
                        
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
              
                </section>
                
                <Footers/>
              
              </main>
              
              <Footer/>
              <ScrollToTop/>
          
            </div>

        </body>
      
    );

};

export default Inscription;