import React, { useEffect, useState } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import AOS from "aos";
import "./Acceuil.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { db } from "../../firebase";

const Acceuil = ({ Titre1, Titre2, Titre3, image }) => {
  
  const [acceuilimage,setAcceuilImage] = useState([])

  const services = {
    slidesPerView: 3,
    speed: 10000,
    loop: true,
    spaceBetween: 60,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 84,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 50,
      },

      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      0: {
        slidesPerView: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();

      const unsub = onSnapshot(
        collection(db, "iss-acceuil"),
        (snapshot) => {
          let list = [];

          snapshot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
            const {image} = doc.data()
            setAcceuilImage(image)
          });

        },
        (error) => {
          console.log(error);
        }
      );

  }, [acceuilimage]);

  return (
    <section
      className="home-slider-area slider-default bg-img"
      style={{
        backgroundImage: `url('${acceuilimage ? acceuilimage : image}'),linear-gradient(rgb(5 5 5 / 60%), rgba(112, 122, 181, 0.6))`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="slider-content-area"
              data-aos="fade-zoom-in"
              data-aos-duration="1300"
            >
              <h5>{Titre1}</h5>
              <h2>
                {Titre2} {""}
                <span>{Titre3}</span>
              </h2>
            </div>

            <div className="swiper-container service-slider-container">
              <div className="swiper-wrapper service-slider service-category">
                <Swiper
                  {...services}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="swiper-slide category-item">
                      <div className="icon">
                        <i className="icofont-pen"></i>
                      </div>

                      <Link to="/FInscription">
                        <h4>INSCRIPTION</h4>
                        <p>En ligne 2022-2023</p>
                      </Link>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiper-slide category-item">
                      <div className="icon">
                        <i class="icofont-web"></i>
                      </div>
                      <h4>Domaine .ac.cd </h4>
                      <p> A propos du domaine iss-lubumbashi.ac.cd</p>
                      {/* <p> L'ancien domaine iss-lubumbashi.ac.cd sera également ouvert et donnera accès à ce même site après les démarches auprès de la SCPT.</p> */}
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="swiper-slide category-item">
                      <div className="icon">
                        <i class="icofont-world"></i>
                      </div>
                      <Link to="/Actualite">
                        <h4>Actualité</h4>
                        <p>Suivez Toute nos actualités</p>
                      </Link>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Acceuil;
