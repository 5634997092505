import React, {useState}from 'react';
import * as emailjs from "emailjs-com";

const SERVICE_ID = "iss_service_id";
const TEMPLATE_ID = "iss_template_id";
const USER_ID = "user_4VqSunVFNxwGW7fIaGjJw";

const Rendezvous = () => {

    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [messages, setMessage] = useState('');
    const [test, setTest] = useState(false);

    const handleClick =(e)=> {

          e.preventDefault();

            var data = {
                To_email:email,
                Nom :nom,
                Message :messages,
                Email : email,
                Telephone :telephone
            };
    
            emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then ((resultat)=>{
          
                setTimeout(() => {
                    setTest(true)
                }, 3000);

            },(error)=>{
                
            });

            e.target.reset();
           
    }

    return (
           
        <section className="appointment-area" data-bg-color="#fff">
                   
            <div className="appointment-form-style1">
                
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    
                    <div className="appointment-form">
                   
                    <div className="section-title">
                        <h5>NOUS SOMMES TOUJOURS PRÊTS À VOUS AIDER</h5>
                        <h2 className="title">Prendre  <span>Renseignements</span></h2>
                    </div>

                    {test ? (

                            <p>
                                Nous sommes actuellement indisponibles. 
                                Nous vous recontacterons dès que l'un de nos opérateurs sera en mesure de vous répondre."
                            </p>

                            ):(
                                
                                <form onSubmit={handleClick}>

                                    <div className="row">

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input className="form-control"
                                                type="text" 
                                                required
                                                name="con_name" 
                                                placeholder="Nom" 
                                                onChange = {(event) => setNom(event.target.value)}>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input 
                                                className="form-control"
                                                type="text" 
                                                name="con_name" 
                                                placeholder="Prénom..." 
                                                onChange = {(event) => setTelephone(event.target.value)}>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input className="form-control" 
                                                type="email" 
                                                name="con_email" 
                                                required
                                                placeholder="sample@gmail.com"  
                                                onChange = {(event) => setEmail(event.target.value)}>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input className="form-control" 
                                                type="text" 
                                                name="con_phone" 
                                                placeholder="Télephone" 
                                                onChange = {(event) => setTelephone(event.target.value)}>
                                            </input>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <textarea 
                                                name="con_message" rows="7" 
                                                placeholder="Votre message ici..." 
                                                onChange = {(event) => setMessage(event.target.value)}>
                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-theme" type="submit">ENVOYER MESSAGE </button>
                                        </div>
                                    </div>
                                    </div>
                               
                                </form>

                            )
                    }
                  
                    </div>
                   
                    <div className="form-message"></div>
                </div>
                </div>
            </div>
            </div>
            
        </section>

    );
};

export default Rendezvous;