import Alert from 'react-bootstrap/Alert';

function Messages({description}) {
  return (
    <>
      {["primary"].map((variant) => (
        <Alert key={variant} variant={variant}>
          {description}
        </Alert>
      ))}
    </>
  );
}

export default Messages;