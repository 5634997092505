// import Notification from './Notification';
// import Deliberation from './Deliberation/Deliberation';
// import TestMap from './Companents/Test/TestMap';

import React from 'react';
import ReactDOM from 'react-dom';
import RoutesForm from "./Companents/RoutesForm";
import { Windmill } from '@windmill/react-ui';
import '../src/assets/css/style.css';

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Windmill>
      <RoutesForm />
    </Windmill>
  </BrowserRouter>,
  document.getElementById("root")
);
