/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useState } from 'react';
import $ from 'jquery';
import AOS from 'aos';
import '../../assets/css/style.css'
import Footer from '../Footer/Footer';
import './Contact.css';
import * as emailjs from "emailjs-com";
import Navigateur2 from '../../Header/Navigateur2';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const SERVICE_ID = "iss_service_id";
const TEMPLATE_ID = "iss_template_id";
const USER_ID = "user_4VqSunVFNxwGW7fIaGjJw";

const Contacts = () => {

    const [nom, setNom] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [messages, setMessage] = useState("");

    const handleClick =(e)=> {

          e.preventDefault();

        var data = {
            To_email:email,
            Nom :nom,
            Message :messages,
            Email : email,
            Telephone :telephone
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then ((resultat)=>{
                setNom("");
                setEmail("");
                setTelephone("");
                setMessage("");
                alert('Email envoyé avec succès');  
        },(error)=>{
                alert(error.text); 
        });

    }
     
    useEffect (()=>{


        $(window).on('load', function() {
          AOS.init();
          stylePreloader();
        });
      
        // Preloader
        function stylePreloader() {
          $('body').addClass('preloader-deactive');
        }

        $(window).on('scroll', function () {
          
          if ($(this).scrollTop() > 250) {
            $('.scroll-to-top').fadeIn();
           
          } else {
            $('.scroll-to-top').fadeOut();
            
          }

          if ($(this).scrollTop() > 250) {
            $('.Whatsappwidget').fadeIn();
           
          } else {
            $('.Whatsappwidget').fadeOut();
            
          }
      
          if($('.sticky-header').length) {
            var windowpos = $(this).scrollTop();
            if (windowpos >= 80) {
              $('.sticky-header').addClass('sticky');
            } else {
              $('.sticky-header').removeClass('sticky');
            }
          }

          if ($(this).scrollTop() > 250) {
            $('.scroll-to-top').fadeIn();
            } else {
            $('.scroll-to-top').fadeOut();
          }

        });

        //Scroll To Top
        $('.scroll-to-top').on('click', function(){
          $('html, body').animate({scrollTop : 0},800);
          return false;
        });

        // Reveal Footer JS
        let revealId = $(".reveal-footer"),
          footerHeight = revealId.outerHeight(),
          windowWidth = $(window).width(),
          windowHeight = $(window).outerHeight();
      
        if (windowWidth > 991 && windowHeight > footerHeight) {
          $(".site-wrapper-reveal").css({
            'margin-bottom': footerHeight + 'px'
          });
        }
      
        $(window).on('scroll', function() {
        });
        
        $(window).on('resize', function() {
        });
        

    },[]);

    return (

        <body>

            <div  className="wrapper">
            
              {/* <div className="preloader-wrap">
                  <div className="preloader">
                      <span className="dot"></span>
                      <div className="dots">
                      <span></span>
                      <span></span>
                      <span></span>
                      </div>
                  </div>
              </div> */}
            
              <Navigateur2/>
                
              <main className="main-content site-wrapper-reveal">
              
                  <div className="contact-map-area">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8402891185374!2d144.95373631590425!3d-37.81720974201477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2ssg!4v1607294780661!5m2!1sen!2ssg"></iframe>
                  </div>

                  <section className="contact-area">

                    <div className="container">

                        <div className="row">

                            <div className="col-lg-12">
                                <div className="contact-info-content">
                                <div className="info-address">
                                    <h2 className="title">ISS -<span>LUBUMBASHI</span></h2>
                                    <p>1559, Boulevard Lumumba (coin Av. Kambove), Lubumbashi RD CONGO</p>

                                    <a href="https://privateemail.com/">{"Email 1:info@iss-lubumbashi.org"}</a><br/>
                                    <a href="https://privateemail.com/">{"Email 2:communication@iss-lubumbashi.org"}</a><br/>   

                                </div>
                                <div className="brand-office">
                                    <div className="info-tem style-two">
                                    <h6>APPELEZ DIRECTEMENT:</h6>
                                    <p>+243 840 716 781</p>
                                    </div>
                                    <div className="info-tem">
                                    <h6>Site:</h6>
                                    <p> Direction Générale | Ex-Taifa | Kiwele | Kilobelobe | </p>
                                    </div>
                                    <div className="info-tem mb-0">
                                    <h6>Heures de travail:</h6>
                                    <p>Lun - Sam : 8h00 - 17h00, dimanche fermé</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                        </div>
                        
                        <div className="row">
                        
                            <div className="col-lg-12">            
                                <div className="contact-form">
                                <div className="section-title text-center">
                                    <h5>Contact</h5>
                                    <h2 className="title">Nous sommes là pour répondre à toutes <span>vos questions.</span></h2>
                                </div>

                                <form onSubmit={handleClick}>
                                    <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <input className="form-control" type="text" name="Nom" placeholder="Nom"  onChange = {(event) => setNom(event.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <input className="form-control" type="email" name="Email" placeholder="Email" onChange = {(event) => setEmail(event.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                        <input className="form-control" type="text" name="Telephone" placeholder="Telephone" onChange = {(event) => setTelephone(event.target.value)}></input>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                        <textarea name="con_message" rows="5" placeholder="Message" onChange = {(event) => setMessage(event.target.value)}></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <div className="form-group mb-0">
                                        <button className="btn btn-theme btn-block" type="submit">Envoyer message</button>
                                        </div>
                                    </div>

                                    </div>
                                </form>

                                </div>
                            
                                <div className="form-message"></div>

                            </div>
                        
                        </div>
                    </div>
                    
                  </section>
              
              </main>
                
             <Footer/>
             <ScrollToTop/>
             
            </div>
        
        </body>
    
    );
};

export default Contacts;