/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */

import React ,{useEffect} from 'react';
import $ from 'jquery';
import AOS from 'aos';
import './Apropos.css';
import Footer from '../Footer/Footer';
import Footers from '../Footer/Footers';
import Navigateur2 from '../../Header/Navigateur2';
import { Link } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

import DG_Office from "../../assets/img/team/DGISS.jpeg";
import SGA_Office from "../../assets/img/team/SGA.JPG";
import ADM_Office from "../../assets/img/team/ADM.JPG";
import SGR_Office from "../../assets/img/team/SGR.JPG";
import AB_Office from "../../assets/img/team/images.png";

const Apropos = () => {

  useEffect (()=>{

    $(window).on('load', function() {
      AOS.init();
      stylePreloader();
    });
  
    // Preloader
    function stylePreloader() {
      $('body').addClass('preloader-deactive');
    }
  
    // Scroll Top Hide Show
    $(window).on('scroll', function(){
      if ($(this).scrollTop() > 250) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
  
      // Sticky Header
      if($('.sticky-header').length){
        var windowpos = $(this).scrollTop();
        if (windowpos >= 80) {
          $('.sticky-header').addClass('sticky');
        } else {
          $('.sticky-header').removeClass('sticky');
        }
      }
  
    });
  
    //Scroll To Top
    $('.scroll-to-top').on('click', function(){
      $('html, body').animate({scrollTop : 0},800);
      return false;
    });
  
    // Reveal Footer JS
    let revealId = $(".reveal-footer"),
      footerHeight = revealId.outerHeight(),
      windowWidth = $(window).width(),
      windowHeight = $(window).outerHeight();
  
    if (windowWidth > 991 && windowHeight > footerHeight) {
      $(".site-wrapper-reveal").css({
        'margin-bottom': footerHeight + 'px'
      });
    }
  
    $(window).on('scroll', function() {
    });
    
    $(window).on('resize', function() {
    });
    
    },[]);
    
    return (
      <body>
        <div className="wrapper">
          {/* <div className="preloader-wrap">
            <div className="preloader">
              <span className="dot"></span>
              <div className="dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div> */}

          <Navigateur2 />

          <main
            className="main-content site-wrapper-reveal"
            style={{ paddingTop: 120 }}
          >
            <section className="page-title-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="page-title-content content-style3">
                      <div className="bread-crumbs bread-crumbs-style2">
                        <a>
                          <Link to="/">Acceuil</Link>
                          <span className="breadcrumb-sep">/</span>
                        </a>
                        <span className="active text-black">Présentation</span>
                      </div>
                      <h4
                        className="title"
                        data-aos="fade-zoom-in"
                        data-aos-duration="1300"
                      >
                        Présentation <span>de l'Institut</span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="blog-area pb-sm-70 pb-lg-90"
              data-padding-bottom="137"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-20">
                    <div className="apropos">
                      <div className="post-item">
                        <div className="content">
                          <h4 className="category">Qui sommes-nous ?</h4>
                          <h4 className="title">
                            <a>
                              Nous sommes l'Institut supérieur de statistique de
                              Lubumbashi, (ISS/Lubumbashi), un établissement
                              public francophone d'enseignement supérieur en
                              République démocratique du Congo, établi à
                              Lubumbashi dans la province du Haut-Katanga.
                            </a>
                          </h4>
                        </div>
                      </div>

                      <div className="post-item">
                        <div className="content">
                          <h4>Notre mission</h4>

                          <h4 className="title">
                            <a>
                              {" "}
                              Notre objectif institutionnel est de former des
                              cadres supérieurs aptes à la professionnalisation
                              et à la recherche dans les sciences et techniques
                              appliquées à la gestion. Notre mission est triple
                              :
                            </a>

                            <ul></ul>

                            <ul style={{ marginLeft: "0px" }}>
                              <p>
                                {" "}
                                <a style={{ fontWeight: 600 }}> 1</a>.Nous
                                formons des cadres supérieurs spécialisés dans
                                les domaines de la Statistique, de
                                l'Informatique de gestion, des Sciences
                                commerciales et financières et de la Démographie
                                appliquée.
                              </p>
                              <p>
                                {" "}
                                <a style={{ fontWeight: 600 }}> 2</a>.Nous
                                organisons la recherche scientifique sur
                                l'adaptation des méthodes mathématiques, des
                                méthodes et technologies informatiques et des
                                théories et méthodes économiques aux
                                problématiques de gestion des organisations ;{" "}
                              </p>
                              <p>
                                {" "}
                                <a style={{ fontWeight: 600 }}> 3</a>.à travers
                                nos formations et nos projets de recherche, nous
                                rendons des services à la Communauté.
                              </p>
                            </ul>
                          </h4>
                        </div>
                      </div>

                      <div className="post-item">
                        <div className="content">
                          <h4 style={{ paddingTop: 20 }}>
                            Notre personnel enseignant
                          </h4>

                          <h4 className="title">
                            <a>
                              Notre personnel enseignant est d'une grande
                              diversité, ce qui contribue à l'excellence de la
                              formation que nous offrons à nos étudiants :
                            </a>

                            <ul></ul>

                            <ul style={{ marginLeft: "0px" }}>
                              <p>
                                {" "}
                                <a style={{ fontWeight: 600 }}> 1</a>. 64
                                enseignants à temps plein parmi lesquels 2
                                Professeurs, 2 Professeurs associés, 18 Chefs de
                                travaux, 36 Assistants, 1 Chargé de pratique
                                professionnelle et 3 Bibliothécaires
                              </p>
                              <p>
                                {" "}
                                <a style={{ fontWeight: 600 }}> 2</a>. 59
                                enseignants à temps partiel parmi lesquels 3
                                Professeurs ordinaires, 4 Professeurs, 11
                                Professeurs associés, 13 Chefs de travaux, 5
                                Assistants et 23 professionnels chargés de
                                cours. Ces enseignants proviennent de
                                l'Université de Lubumbashi (UNILU), de
                                l'Institut supérieur de commerce de Lubumbashi
                                (I.S.C./L'shi), de l'Institut supérieur
                                pédagogique de Lubumbashi (I.S.P./L'shi), de
                                l'Institut supérieur d'études sociales de
                                Lubumbashi (I.S.E.S./L'shi), de quelques autres
                                établissements d'enseignement supérieur de la
                                ville de Lubumbashi ainsi que de grandes
                                entreprises de l'ex-province du Katanga dont la
                                Générale des carrières et des mines (Gécamines,
                                G.C.M.), la Société nationale des chemins de fer
                                du Congo (S.N.C.C.), la Tenke Fungurume Mining
                                (T.F.M.) et la Katanga Copper Company (K.C.C.).
                              </p>
                            </ul>
                          </h4>
                        </div>
                      </div>

                      <div className="post-item">
                        <div className="content">
                          <h4 style={{ paddingTop: 20 }}>Nos diplômés</h4>

                          <h4 className="title">
                            <a>
                              De <a style={{ fontWeight: 600 }}>1973 à 2020,</a>{" "}
                              nous avons produit plusieurs cadres, gradués (Bac
                              + 2) et licenciés (Bac + 4) dans nos filières de
                              formation :
                            </a>

                            <ul></ul>

                            <ul style={{ marginLeft: "0px" }}>
                              <p>
                                Gradués :<a style={{ fontWeight: 600 }}>1917</a>
                                en Statistique,
                                <a style={{ fontWeight: 600 }}>3915</a>en
                                Informatique de gestion, 2027 en Sciences
                                commerciales et financières, 121 en Démographie
                                appliquée
                              </p>
                              <p>
                                Licenciés :{" "}
                                <a style={{ fontWeight: 600 }}>933</a> en
                                Statistique,{" "}
                                <a style={{ fontWeight: 600 }}>1056</a>en
                                Informatique de gestion, 733 en Sciences
                                commerciales et financières, 41 en Démographie
                                appliquée.
                              </p>
                            </ul>
                          </h4>
                        </div>
                      </div>

                      <div className="post-item">
                        <div className="content">
                          <h4 style={{ paddingTop: 20 }}>Comité de Gestion</h4>

                          <h4 className="title">
                            <a>
                              Le Comité de Gestion de l'ISS est actuellement
                              composé de :
                            </a>
                            <ul></ul>

                            <ul style={{ marginLeft: "38px" }}>
                              <p>
                                1.Prof.{" "}
                                <a style={{ fontWeight: 600 }}>
                                  BALLOY MWANZA Perry
                                </a>{" "}
                                : Directeur Général
                              </p>
                              <p>
                                2.Prof.{" "}
                                <a style={{ fontWeight: 600 }}>
                                  LUPITSHI WA NUMBI Norbert
                                </a>{" "}
                                : Secrétaire Général Académique
                              </p>
                              <p>
                                3.CT{" "}
                                <a style={{ fontWeight: 600 }}>
                                  KISALA BELANGE
                                </a>{" "}
                                : Secrétaire Générale à la Recherche
                              </p>
                              <p>
                                4.Professeur{" "}
                                <a style={{ fontWeight: 600 }}>
                                  DIKASA ENGONDO Modeste
                                </a>{" "}
                                : Secrétaire Général Administratif
                              </p>

                              <p>
                                5. Directrice{" "}
                                <a style={{ fontWeight: 600 }}>
                                  MBANGU MILEMBA Petronie{" "}
                                </a>{" "}
                                : Administrateur du Budget
                              </p>
                            </ul>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* ab */}

                      <div className="col-md-6">
                        <div className="gallery-item mb-30">
                          <div className="thumb">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={AB_Office}
                            >
                              <img src={AB_Office} alt="Image"></img>
                            </a>
                            <div className="overlay">
                              <i className="icofont-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* adm */}

                      <div className="col-md-6">
                        <div className="gallery-item mb-30">
                          <div className="thumb">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={ADM_Office}
                            >
                              <img src={ADM_Office} alt="Image"></img>
                            </a>
                            <div className="overlay">
                              <i className="icofont-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* DG */}
                      <div className="col-md-12">
                        <div className="gallery-item">
                          <div className="thumb">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={DG_Office}
                            >
                              <img src={DG_Office} alt="Image"></img>
                            </a>
                            <div className="overlay">
                              <i className="icofont-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ab */}

                      <div className="col-md-6">
                        <div className="gallery-item mb-30">
                          <div className="thumb">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={SGA_Office}
                            >
                              <img src={SGA_Office} alt="Image"></img>
                            </a>
                            <div className="overlay">
                              <i className="icofont-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* adm */}

                      <div className="col-md-6">
                        <div className="gallery-item mb-30">
                          <div className="thumb">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={SGR_Office}
                            >
                              <img src={SGR_Office} alt="Image"></img>
                            </a>
                            <div className="overlay">
                              <i className="icofont-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footers />
            </section>
          </main>

          <Footer />

          <ScrollToTop />
        </div>
      </body>
    );

};

export default Apropos;