/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Swiper from "react-id-swiper";
import "./Temoignage.css";

import numero1 from "../../assets/img/brand-logo/B40.png";
import numero2 from "../../assets/img/brand-logo/Rawbank.png";
import numero3 from "../../assets/img/brand-logo/codepro.png";
import BGTemoignage from "../../assets/img/photos/BGtemoignage.jpg";

const Temoignage = () => {

  const etudiants = {
    slidesPerView: 3,
    speed: 10000,
    loop: true,
    spaceBetween: 60,
    breakpoints: {
      1200: {
        slidesPerView: 3,
        spaceBetween: 84,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 50,
      },

      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      0: {
        slidesPerView: 1,
      },
    },
  };

  const entreprise = {
    slidesPerView: 5,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 50,
    },
    breakpoints: {
      1200: {
        slidesPerView: 5,
        spaceBetween: 100,
      },

      992: {
        slidesPerView: 4,
        spaceBetween: 90,
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 110,
      },

      576: {
        slidesPerView: 3,
        spaceBetween: 60,
      },

      380: {
        slidesPerView: 3,
        spaceBetween: 30,
      },

      0: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  };
  
  return (
    <section
      className="temoignage-area temoignage-default-area bg-img"
      style={{
        background: `url('${BGTemoignage}') no-repeat center center`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h5>TÉMOIGNAGE</h5>
              <h2 className="title">
                Approuvé <span>par nos etudiants</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Swiper
             
              className="swiper-container temoignage-slider-container"
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              <div className="swiper-wrapper temoignage-slider">
                <div className="swiper-slide temoignage-item">
                  {/* <div className="thumb">
                                        <img src={temoins} alt="Image"></img>
                                    </div> */}
                  <div className="client-content">
                    <p>
                      Chaque jour, je me lève et commence une nouvelle journée,
                      c’est ma plus grande réussite. Étudier a ISS a été une
                      expérience de vie unique qui me porte chance.
                    </p>
                  </div>
                  <div className="client-info">
                    <div className="desc">
                      <h4 className="name">Marleine Tshakwa</h4>
                      <p className="client-location">Congo RD, Lubumbashi</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper temoignage-slider">
                <div className="swiper-slide temoignage-item">
                  {/* <div className="thumb">
                                        <img src={temoins} alt="Image"></img>
                                    </div> */}
                  <div className="client-content">
                    <p>
                      Chaque jour, je me lève et commence une nouvelle journée,
                      c’est ma plus grande réussite. Étudier a ISS a été une
                      expérience de vie unique qui me porte chance.
                    </p>
                  </div>
                  <div className="client-info">
                    <div className="desc">
                      <h4 className="name">Gracia Mudjana</h4>
                      <p className="client-location">
                        Golden Lotus Apartment, Brooklyn, USA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="brand-logo-area brand-logo-default-area">
              <div
                className="div-container brand-logo-slider-container"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <Swiper
                  {...entreprise}
                  className="div-wrapper brand-logo-slider"
                >
                  <div className="div-slide brand-logo-item">
                    <a href="https://web.facebook.com/gaspard.koya.dieudonne">
                      <img src={numero1} alt="iss-image"></img>
                    </a>
                  </div>

                  <div className="div-slide brand-logo-item">
                    <a href="https://rawbank.com">
                      <img src={numero2} alt="iss-image"></img>
                    </a>
                  </div>

                  <div className="div-slide brand-logo-item">
                    <a href="https://web.facebook.com/gaspard.koya.dieudonne">
                      <img src={numero3} alt="iss-image"></img>
                    </a>
                  </div>

                  <div className="div-slide brand-logo-item">
                    <a href="#/">
                      <img src={numero1} alt="iss-image"></img>
                    </a>
                  </div>

                  <div className="div-slide brand-logo-item">
                    <a href="https://rawbank.com">
                      <img src={numero2} alt="iss-image"></img>
                    </a>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Temoignage;
