import React, {useState}from 'react';
import * as emailjs from "emailjs-com";

const SERVICE_ID = "iss_service_id";
const TEMPLATE_ID = "iss_template_id";
const USER_ID = "user_4VqSunVFNxwGW7fIaGjJw";

const FormContact = () => {

    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [messages, setMessage] = useState('');

    const handleClick =(e)=> {

        e.preventDefault();

        var data = {
            To_email:email,
            Nom :nom,
            Message :messages,
            Email : email,
            Telephone :telephone
        };
  
        emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then ((resultat)=>{
            
        },(error)=>{
                alert(error.text); 
        });

        e.target.reset();

    }

    return (
        
        <div className="widget-appointment-form">

            <h4 className="title">PRENEZ RENDEZ-VOUS</h4>

            <form onSubmit={handleClick}>

                    <div className="col-md-16">
                        <div className="form-group">
                            <input className="form-control"
                                type="text" 
                                required
                                name="con_name" 
                                placeholder="Entrer votre nom..." 
                                onChange = {(event) => setNom(event.target.value)}>
                            </input>
                        </div>
                    </div>

                    <div className="col-md-16">
                        <div className="form-group">
                            <input 
                                
                                className="form-control"
                                type="text" 
                                name="con_name" 
                                placeholder="Entrer votre prénom..." 
                                onChange = {(event) => setTelephone(event.target.value)}>
                            </input>
                        </div>
                    </div>

                    <div className="col-md-16">
                        <div className="form-group">
                            <input className="form-control" 
                                type="email" 
                                name="con_email" 
                                required
                                placeholder="sample@gmail.com"  
                                onChange = {(event) => setEmail(event.target.value)}>
                            </input>
                        </div>
                    </div>

                    <div className="col-md-16">
                        <div className="form-group">
                            <input className="form-control" 
                                type="text" 
                                name="con_phone" 
                                placeholder="Télephone" 
                                onChange = {(event) => setTelephone(event.target.value)}>
                            </input>
                        </div>
                    </div>

                    <div className="col-md-18">
                        <div className="form-group mb-0">
                            <textarea 
                                name="con_message" rows="7" 
                                placeholder="Votre message ici..." 
                                onChange = {(event) => setMessage(event.target.value)}>
                            </textarea>
                        </div>
                    </div>

                    <div className="col-md-16">
                        <div className="form-group mb-0">
                            <button className="btn btn-theme" type="submit">ENVOYER MESSAGE </button>
                        </div>
                    </div>
                    
            </form>
        
        </div>
    );
};

export default FormContact;