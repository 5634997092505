/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Swiper from "react-id-swiper";
import { Autoplay, Pagination, Navigation } from "swiper";

import DG from "../../assets/img/team/DGISS2.jpeg";
import SGA from "../../assets/img/team/SGA.JPG"; // ok
import ADM from "../../assets/img/team/ADM.JPG"; // ok
import SGR from "../../assets/img/team/SGR.JPG"; // ok
import AB from "../../assets/img/team/images.png";

import "./Comite.css";
import { Link } from "react-router-dom";

const Comite = () => {

  const params = {

    slidesPerView: 3,
    speed: 1000,
    loop: true,
    spaceBetween: 30,
    breakpoints: {
      1200: {
        slidesPerView: 3,
      },

      991: {
        slidesPerView: 2,
      },

      767: {
        slidesPerView: 2,
      },

      560: {
        slidesPerView: 2,
      },

      0: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <section className="team-area team-default-area" id="Comite">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="section-title text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h5>Comité de gestion</h5>
              <h2 className="title">
                Une équipe <span>forte au cœur de l'iss</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="swiper-container team-slider-container"
              data-aos="fade-up"
              data-aos-duration="1300"
            >
              <Swiper
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: true,
                }}
                {...params}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="swiper-wrapper team-slider"
              >
                <div>
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img src={DG} alt="iss-lubumbashi"></img>
                    </div>
                    <div className="content">
                      <div className="member-info">
                        <h4 className="name">Prof. BALLOY MWANZA Perry</h4>
                        <p>Directeur Général</p>
                        <Link to="/Directeur">
                          <a className="btn-link">En savoir plus</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img src={SGA} alt="iss-lubumbashi"></img>
                    </div>
                    <div className="content">
                      <div className="member-info">
                        <h4 className="name">
                          Prof. LUPITSHI WA NUMBI Norbert
                        </h4>
                        <p>Secrétaire Géneral Académique</p>
                        <Link to="/Academique">
                          <a className="btn-link">En savoir plus</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img src={ADM} alt="iss-lubumbashi"></img>
                    </div>
                    <div className="content">
                      <div className="member-info">
                        <h4 className="name">Prof. DIKASA ENGONDO Modeste</h4>
                        <p>Secrétaire Géneral Administratif</p>
                        <Link to="/Administratif">
                          <a className="btn-link">En savoir plus</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img src={SGR} alt="iss-lubumbashi"></img>
                    </div>
                    <div className="content">
                      <div className="member-info">
                        <h4 className="name">CT KISALA BELANGE Bel-Ange</h4>
                        <p>Secrétaire Générale à la Recherche</p>
                        <Link to="/Secretaire">
                          <a className="btn-link">En savoir plus</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="swiper-slide team-member">
                    <div className="thumb">
                      <img src={AB} alt="AB-iss-lubumbashi"></img>
                    </div>
                    <div className="content">
                      <div className="member-info">
                        <h4 className="name">MBANGU MILEMBA Petronie</h4>
                        <p>Administratrice de Budget</p>
                        <Link to="/Administratrice">
                          <a className="btn-link">En savoir plus</a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comite;
