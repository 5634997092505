import React from 'react'

import WhatsAppWidget from 'react-whatsapp-widget'

import 'react-whatsapp-widget/dist/index.css'
//https://github.com/ann0nip/react-whatsapp-widget

const Whatsappwidget = () => {
  return <WhatsAppWidget 
            phoneNumber='+243992000085' 
            companyName='ISS-LUBUNBASHI'
            message="Nous sommes actuellement indisponibles. Nous vous recontacterons dès que l'un de nos opérateurs sera en mesure de vous répondre. Veuillez nous laisser le message."
            sendButton="Envoyer"
            textReplyTime=""

          />
}

export default Whatsappwidget