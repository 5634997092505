/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { Link } from "react-router-dom";
import Navigateur2 from "../../Header/Navigateur2";
import {signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { auth } from "../../firebase";

import Messages  from "./Messages";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Auth = ({ user }) => {

  const [state, setState] = useState(initialState);
  const [message,setMessage] = useState(false);

  const { email, password, firstName, lastName, confirmPassword } = state;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setMessage(false)
  };

  const handleAuth = async (e) => {

    e.preventDefault();

    if (email && password) {

         await signInWithEmailAndPassword(auth, email, password).then((reponse)=>{
            navigate("/AddBlog");
        }).catch((err)=>{
          setMessage(true)
            console.log(err)
        })
     
    } else {
      navigate("/Auth");
    }
  };

  useEffect(()=>{
     if (user !== null){
        navigate("/AddBlog");
     }
  },[])

  return (
    <body>
      <div className="wrapper">
        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 130 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content content-style3">
                    <div className="bread-crumbs bread-crumbs-style2">
                      <a>
                        <Link to="/">Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active text-black">
                        Authentification
                      </span>
                    </div>

                    <div className="section-titles">
                      <h4
                        className="title"
                        data-aos="fade-zoom-in"
                        data-aos-duration="1300"
                      >
                        Authentification<span></span>
                      </h4>

                      <div className="division"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="appointments-area" data-bg-color="#fff">
            <div className="appointments-form-style1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="appointments-form">

                      {message ?  <Messages description="Une erreur est survenue lors de l'authentification"/> : null}

                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={email}
                                className="form-control input-text-box"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control input-text-box"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="col-12 py-3 text-center">
                        <button onClick={handleAuth} className="btn btn-add">
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </body>
  );
};

export default Auth;
