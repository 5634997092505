/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './Footer.css';

const Footers = () => {

    return (
      
      <div className="footers">
                
        <footer className="footer">
          <div className="footer-bottom">
            <div className="container">
              <div className="row text-center">
                <div className="col-sm-12">
                  <div className="widget-copyright">
                    <p><span>© 2021 </span > <a style={{color:'#000'}}>Institut Supérieur de Statistique de Lubumbashi. |</a> 
                    <i class="icofont-responsive icofont-5x"></i>
                        <a target="_blank" href="https://" style={{color:'#000'}}>CODEPRO</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    );
};

export default Footers;