/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import $ from 'jquery';
import AOS from 'aos';
// import ABIMAGE from '../../assets/img/team/';
import DG from "../../assets/img/team/images.png";

import Footer from '../Footer/Footer';
import Footers from '../Footer/Footers';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Navigateur2 from '../../Header/Navigateur2';
import { Link } from 'react-router-dom';
import FormContact from './FormContact';

const AB = () => {

    useEffect (()=>{

        $(window).on('load', function() {
            AOS.init();
            stylePreloader();
        });
        
        // Preloader
        function stylePreloader() {
        $('body').addClass('preloader-deactive');
        }
        
        $(window).on('scroll', function () {

          if ($(this).scrollTop() > 250) {
            $('.scroll-to-top').fadeIn();
          } else {
            $('.scroll-to-top').fadeOut();
          }

          if($('.sticky-header').length) {
            var windowpos = $(this).scrollTop();
            if (windowpos >= 80) {
              $('.sticky-header').addClass('sticky');
            } else {
              $('.sticky-header').removeClass('sticky');
            }
          }

          if ($(this).scrollTop() > 250) {
            $('.scroll-to-top').fadeIn();
            } else {
            $('.scroll-to-top').fadeOut();
          }

        });

        //Scroll To Top
        $('.scroll-to-top').on('click', function(){
          $('html, body').animate({scrollTop : 0},800);
          return false;
        });


        // Reveal Footer JS
        let revealId = $(".reveal-footer"),
          footerHeight = revealId.outerHeight(),
          windowWidth = $(window).width(),
          windowHeight = $(window).outerHeight();
      
        if (windowWidth > 991 && windowHeight > footerHeight) {
          $(".site-wrapper-reveal").css({
            'margin-bottom': footerHeight + 'px'
          });
        }
      
        $(window).on('scroll', function() {
        });
        
        $(window).on('resize', function() {
        });
        

    },[]);

    return (
      <body>
        <div className="wrapper">
          <Navigateur2 />

          <main
            className="main-content site-wrapper-reveal"
            style={{ paddingTop: 140 }}
          >
            <section className="page-title-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="page-title-content">
                      <div className="bread-crumbs">
                        <a>
                          <Link to="/">Acceuil</Link>
                          <span className="breadcrumb-sep">/</span>
                        </a>
                        <span className="active">ADM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="department-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="department-wrpp">
                      <div className="sidebar-wrapper">
                        <div className="widget-item">
                          <h4
                            className="widget-title"
                            style={{ color: "#000" }}
                          >
                            Nos Services
                          </h4>
                        </div>

                        <div className="widget-item">
                          <div className="widget-work-hours">
                            <h4 className="title">Heures de Services</h4>
                            <ul>
                              <li>
                                <span className="work-day">Lundi</span>
                                <span className="work-hours">8:00 - 16:00</span>
                              </li>
                              <li>
                                <span className="work-day">Mardi</span>
                                <span className="work-hours">8:00 - 16:30</span>
                              </li>
                              <li>
                                <span className="work-day">Mercredi</span>
                                <span className="work-hours">8:00 - 16:30</span>
                              </li>
                              <li>
                                <span className="work-day">Jeudi</span>
                                <span className="work-hours">8:00 - 16:30</span>
                              </li>
                              <li>
                                <span className="work-day">Vendredi</span>
                                <span className="work-hours">8:00 - 16:30</span>
                              </li>
                              <li>
                                <span className="work-day">Samedi</span>
                                <span className="work-hours">8:00 - 12:00</span>
                              </li>
                            </ul>
                            <a className="btn-theme btn-white" href="#/">
                              EN SAVOIR PLUS
                            </a>
                          </div>
                        </div>

                        <div className="widget-item">
                          <FormContact />
                        </div>
                      </div>

                      <div className="department-content">
                        <h2
                          className="title"
                          data-aos="fade-zoom-in"
                          data-aos-duration="1300"
                        >
                          L'Administratrice du Budget
                        </h2>

                        <div className="swiper-container department-gallery">
                          <div className="swiper-wrapper gallery-slider">
                            <div className="swiper-slide">
                              <div className="gallery-item">
                                <div className="thumb">
                                  <a
                                    className="lightbox-image"
                                    data-fancybox="gallery"
                                    href={DG}
                                  >
                                    <img src={DG} alt="Image"></img>
                                  </a>
                                  <div className="overlay">
                                    <i className="icofont-plus"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="content">
                          <h2 className="title">
                            Profil &<span> Responsabilité</span>
                          </h2>
                          <p>
                            L’Administrateur du budget est membre du comité de
                            gestion. En tant que tel, il se charge de la gestion
                            budgétaire quotidienne, financière et des activités
                            d’autofinancement. Ces attributions sont exercées
                            par le secrétaire General Administratif de
                            l’établissement où le poste d’Administrateur du
                            budget n’est ni prévu ni pourvu. En cette matière de
                            gestion budgétaire, l’Administrateur du budget :
                          </p>

                          <ul className="list-style1">
                            <li>
                              Coordonne les travaux de prévisions budgétaires de
                              l’ensemble de l’établissement et supervise
                              directement ceux de ses services ;
                            </li>
                            <li>
                              Présente le projet de prévisions budgétaires de
                              l’ensemble de l’établissement au comité de gestion
                              pour adoption ;
                            </li>
                            <li>
                              Soumet au comité de gestion la répartition
                              budgétaire annuelle ainsi que les aménagements qui
                              s’y imposent en vertu de l’article 22 du règlement
                              financier de l’enseignement supérieur et
                              universitaire ;
                            </li>
                          </ul>
                        </div>

                        <div className="faq-area">
                          <div className="accordian-content">
                            <div className="accordion" id="accordionStyle">
                              <div className="accordion-item">
                                <div
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    Autres
                                    <i className="icon icon-plus icofont-plus"></i>
                                    <i className="icon icon-minus icofont-minus"></i>
                                  </button>
                                </div>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionStyle"
                                >
                                  <div className="accordion-body">
                                    L'ISS vise l'excellence dans
                                    l'enseignement,la réussite et la
                                    satisfaction des étudiants dans un
                                    environnement universitaire en constante
                                    amelioration"
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footers />
          </main>

          <Footer />

          <ScrollToTop />
        </div>
      </body>
    );

};

export default AB;