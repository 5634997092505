/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import $ from "jquery";
import AOS from "aos";
import "./Service.css";
import SGA from "../../assets/img/team/SGA.JPG";
import Footer from "../Footer/Footer";
import Calendrier from "../Calendrier/Calendrier";
//Doownloader
import useDownloader from "react-use-downloader";

import "./Service.css";
import { Rapports, Communication } from "./Data";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { Link } from "react-router-dom";
import Navigateur2 from "../../Header/Navigateur2";
import FormContact from "./FormContact";
import Footers from "../Footer/Footers";

const ACADEMIQUE = () => {
 
  const {
    // size,
    elapsed,
    // percentage,
    download,
    // cancel,
    // error,
    // isInProgress,
  } = useDownloader();

  useEffect(() => {
    $(window).on("load", function () {
      AOS.init();
      stylePreloader();
    });

    // Preloader
    function stylePreloader() {
      $("body").addClass("preloader-deactive");
    }

    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }

      if ($(".sticky-header").length) {
        var windowpos = $(this).scrollTop();
        if (windowpos >= 80) {
          $(".sticky-header").addClass("sticky");
        } else {
          $(".sticky-header").removeClass("sticky");
        }
      }

      if ($(this).scrollTop() > 250) {
        $(".scroll-to-top").fadeIn();
      } else {
        $(".scroll-to-top").fadeOut();
      }
    });

    //Scroll To Top
    $(".scroll-to-top").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, 800);
      return false;
    });

    // Reveal Footer JS
    let revealId = $(".reveal-footer"),
      footerHeight = revealId.outerHeight(),
      windowWidth = $(window).width(),
      windowHeight = $(window).outerHeight();

    if (windowWidth > 991 && windowHeight > footerHeight) {
      $(".site-wrapper-reveal").css({
        "margin-bottom": footerHeight + "px",
      });
    }

    $(window).on("scroll", function () {});

    $(window).on("resize", function () {});
  }, []);

  return (
    <body>
      <div className="wrapper">
        <Navigateur2 />

        <main
          className="main-content site-wrapper-reveal"
          style={{ paddingTop: 140 }}
        >
          <section className="page-title-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-title-content">
                    <div className="bread-crumbs">
                      <a href="index.html">
                        <Link to="/"> Acceuil</Link>
                        <span className="breadcrumb-sep">/</span>
                      </a>
                      <span className="active">Academique</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="department-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="department-wrpp">
                    <div className="sidebar-wrapper">
                      <div className="widget-item">
                        <h4
                          className="widget-title"
                          data-aos="fade-zoom-in"
                          data-aos-duration="1300"
                          style={{ color: "#000" }}
                        >
                          Services Academique
                        </h4>

                        <div className="widget-side-nav">
                          <ul>
                            <li>
                              <a className="active">Inscription</a>
                            </li>

                            <li>
                              <a>Calendrier Academique</a>
                            </li>

                            <li>
                              <a>Authentification de pièces académiques</a>
                            </li>

                            <li>
                              <a>Obtention de pièces académiques</a>
                            </li>

                            <li>
                              <a>Charge Horaire</a>
                            </li>

                            <li>
                              <a>Délibération</a>
                            </li>

                            <li>
                              <a>Communications du SGAC</a>
                            </li>

                            <li>
                              <a>Rapports académiques</a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="widget-item">
                        <div className="widget-work-hours">
                          <h4 className="title">Heures de Services</h4>
                          <ul>
                            <li>
                              <span className="work-day">Lundi </span>
                              <span className="work-hours">8:00 - 16:00</span>
                            </li>
                            <li>
                              <span className="work-day">Mardi </span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Mercredi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Jeudi </span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Vendredi</span>
                              <span className="work-hours">8:00 - 16:30</span>
                            </li>
                            <li>
                              <span className="work-day">Samedi </span>
                              <span className="work-hours">8:00 - 12:00</span>
                            </li>
                          </ul>
                          <a className="btn-theme btn-white">EN SAVOIR PLUS</a>
                        </div>
                      </div>

                      {/* <div className="widget-item">
                        <FormContact />
                      </div> */}
                    </div>

                    <div className="department-content">
                      <h2 className="title">Secrétaire Général Académique</h2>

                      <div className="swiper-container department-gallery">
                        <div className="swiper-wrapper gallery-slider">
                          <div className="swiper-slide">
                            <div className="gallery-item">
                              <div className="thumb">
                                <a
                                  className="lightbox-image"
                                  data-fancybox="gallery"
                                  href={SGA}
                                >
                                  <img src={SGA} alt="Image"></img>
                                </a>
                                <div className="overlay">
                                  <i className="icofont-plus"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content">
                        <h2 className="title">
                          Profil & <span>Responsabilité</span>
                        </h2>

                        <p>
                          Le secrétaire général académique est membre du comité
                          de gestion. Il supervise et coordonne les activités
                          des services relevant de son ressort. Il fait rapport
                          des activités au chef d’établissement dans la
                          condition prévue par le règlement organique. A ce
                          titre, il est chargé :
                        </p>

                        <ul className="list-style1">
                          <li>
                            Suivre, au jour le jour, les activités de tout le
                            secteur académique de l’établissement
                          </li>
                          <li>
                            Assister d’une façon régulière aux réunions de
                            facultés »s ou sections et à toutes les réunions
                            d’une certaine importance pour la vie académique.
                            Certes, il n’est pas possible d’assister à toutes
                            les réunions, mais il doit avoir une participation
                            programmée et en faire un rapport à la hiérarchie
                          </li>
                          <li>....</li>
                        </ul>
                      </div>

                      {/* <div className="faq-area">

                        <h2 className="title" style={{ marginBottom: 23, marginTop: 23 }}>
                          Rapports <span>académiques</span>
                        </h2>

                        <div className="accordian-content">
                          <div className="accordion">
                            {Rapports.map((files, index) => {
                              return (
                                <div className="accordion-item">
                                <div className="accordion-header">
                                 
                                  <button
                                    key={index}
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                     {files.name}

                                     <a href={files.file} 
                                        download 
                                        className="icofont-download" >
                                     </a>

                                    <i 
                                      className="icofont-download"
                                      key={index}
                                      onClick={() =>
                                        download(
                                          files.file,
                                          files.name,
                                        ) 
                                      }
                                    ></i>

                                    {elapsed ? (
                                      <p
                                        style={{ color: "#e02416" }}
                                        key={index}
                                      >
                                        {elapsed}%
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                              </div>
                              );
                            })}
                          </div>
                        </div>

                        <h2 className="title" style={{ marginBottom: 23 }}>
                          Communications du <span>SGAC</span>
                        </h2>

                        <div className="accordian-content">
                          <div className="accordion">
                            {
                              Communication.map((files, index) => {
                                return (
                                  <div className="accordion-item">
                                  <div className="accordion-header">
                                  
                                    <button
                                      key={index}
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      {files.name}

                                      <a href={files.file} 
                                          download 
                                          className="icofont-download" >
                                      </a>

                                      <i 
                                        className="icofont-download"
                                        key={index}
                                        onClick={() =>
                                          download(
                                            files.file,
                                            files.name,
                                          ) 
                                        }
                                      ></i>

                                      {elapsed ? (
                                        <p
                                          style={{ color: "#e02416" }}
                                          key={index}
                                        >
                                          {elapsed}%
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </button>
                                  </div>
                                </div>
                                );
                              })
                            }
                          </div>
                        </div>

                      </div> */}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Calendrier /> */}
          </section>
          <Footers />
        </main>

        <Footer />

        <ScrollToTop />
      </div>
    </body>
  );
};

export default ACADEMIQUE;
